export const primary = {
  500: '#c9fb00',
  600: '#018436',
};

export const secondary = {
  400: '#d6dcdc',
};

export const warning = {
  main: '#FB6900',
  dark: '#E86101',
  light: '#FC7F25',
};

export const common = {
  white: '#fff',
  black: '#07070c',
};
