import {Box, Container, Typography} from '@mui/material'
import * as React from 'react'

type MessageSectionProps = {
  title: string | React.ReactNode
  subtitle?: string | React.ReactNode
  message?: string | React.ReactNode
  children?: React.ReactNode
}

function MessageSection({
  title,
  subtitle,
  message,
  children,
}: MessageSectionProps) {
  return (
    <Box component="section" paddingY={7} textAlign="center">
      <Container maxWidth="lg">
        {title && <Typography variant="h5">{title}</Typography>}
        {subtitle && <Typography component="strong">{subtitle}</Typography>}
        <Box>
          <p>{message}</p>
          {children}
        </Box>
      </Container>
    </Box>
  )
}

export {MessageSection}
