import {useCallback, useEffect, useState} from 'react'

import {useClient} from '~/context/auth-context'
import type {ResetMFADto} from '~/types'

type UseResetMFAOptions = {
  variables: {
    token?: string
  }
}

export const useResetMFA = ({variables}: UseResetMFAOptions) => {
  const client = useClient<void, ResetMFADto>()

  const [isLoading, setIsLoading] = useState(true)
  const [isSuccess, setIsSuccess] = useState(false)

  const resetMFA = useCallback(async () => {
    try {
      await client('signup/v1/profiles/reset-mfa', {
        data: {
          token: variables.token ?? '',
        },
      })
      setIsSuccess(true)
    } catch {
      setIsSuccess(false)
    } finally {
      setIsLoading(false)
    }
  }, [client, variables.token])

  useEffect(() => {
    resetMFA()
  }, [resetMFA])

  return {isLoading, isSuccess}
}
