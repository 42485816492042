import { MenuItem } from '@mui/material';
import { Children, ReactElement, ReactNode, cloneElement, isValidElement } from 'react';

type Option = {
  label: string;
  value: string;
};

/**
 * [1] append data-ansarada-ccd for each menu items
 * [2] append None option in case `noneOption` exists
 */
export function transformChildrenArray(
  children: ReactElement,
  dataAnsaradaCcd?: boolean,
  noneOption?: boolean,
): ReactElement[] {
  const securedChildren: ReactElement[] =
    Children.map<ReactElement, ReactElement>(children, (child) =>
      cloneElement(child as ReactElement, {
        'data-ansarada-ccd': dataAnsaradaCcd,
      }),
    ) ?? [];

  if (noneOption) {
    securedChildren.unshift(<MenuItem value="">None</MenuItem>);
  }

  return securedChildren;
}

export function getChildrenLabels(childNodes: ReactNode[]): (Option | null)[] {
  return childNodes
    .map((child): Option | null => {
      if (!isValidElement(child)) return null;
      return { value: child.props.value as string, label: child.props.children as string };
    })
    .filter(Boolean);
}
