import { ColorCompound, ColorFromPalette } from './utils';

export const ansarada = '#c9fb00' as ColorFromPalette;
export const order = '#ffffff' as ColorFromPalette;
export const chaos = '#07070c' as ColorFromPalette;

export const text = {
  base: '#656662' as ColorFromPalette,
  dark: '#07070c' as ColorFromPalette,
  light: '#8e9089' as ColorFromPalette,
  white: '#ffffff' as ColorFromPalette,
};

export const grey = {
  _100: '#f1f1f1',
  _200: '#e5e1e1',
  _300: '#dddddd',
  _400: '#d5d2d2',
  _500: '#c4c2c2',
  _600: '#8e9089',
  _700: '#656662',
} as unknown as ColorCompound;

export const mercury = {
  _100: '#d1f5fe',
  _200: '#9eeafe',
  _300: '#6be0fd',
  _400: '#00c9fb',
  _500: '#0293b8',
  _600: '#036c88',
  _700: '#053241',
} as unknown as ColorCompound;

export const chlorine = {
  _100: '#d1fef5',
  _200: '#9efeea',
  _300: '#6bfde0',
  _400: '#00fbc9',
  _500: '#02b794',
  _600: '#03866e',
  _700: '#053d36',
} as unknown as ColorCompound;

export const krypton = {
  _100: '#d1fee4',
  _200: '#9efec6',
  _300: '#6bfda8',
  _400: '#00fb69',
  _500: '#02b74f',
  _600: '#03863c',
  _700: '#05552a',
} as unknown as ColorCompound;

export const citrus = {
  _100: '#f5fed1',
  _200: '#eafe9e',
  _300: '#e0fd6b',
  _400: '#c9fb00',
  _500: '#93b703',
  _600: '#6c8606',
  _700: '#323d09',
} as unknown as ColorCompound;

export const helium = {
  _100: '#fef5d1',
  _200: '#feea9e',
  _300: '#fde06b',
  _400: '#fbc900',
  _500: '#b79303',
  _600: '#866c06',
  _700: '#3d3209',
} as unknown as ColorCompound;

export const bromine = {
  _100: '#fee4d1',
  _200: '#fec69e',
  _300: '#fda86b',
  _400: '#fb6900',
  _500: '#e86101',
  _600: '#c35203',
  _700: '#552608',
} as unknown as ColorCompound;

export const neon = {
  _100: '#fed1e4',
  _200: '#fe9ec6',
  _300: '#fd6ba8',
  _400: '#fb0069',
  _500: '#e80162',
  _600: '#c30154',
  _700: '#55052a',
} as unknown as ColorCompound;

export const radon = {
  _100: '#fed1f5',
  _200: '#fe9eea',
  _300: '#fd6be0',
  _400: '#fb00c9',
  _500: '#b70294',
  _600: '#86036e',
  _700: '#3d0536',
} as unknown as ColorCompound;

export const argon = {
  _100: '#f5d1fe',
  _200: '#ea9efe',
  _300: '#e95bfd',
  _400: '#c900fb',
  _500: '#9302b8',
  _600: '#6c0388',
  _700: '#320541',
} as unknown as ColorCompound;
