import {type AppState, Auth0Provider, useAuth0} from '@auth0/auth0-react'
import * as React from 'react'
import {useNavigate} from 'react-router-dom'

import appConfig from '~/config'
import {type ConfigType, client} from '~/lib/api-client'

type AuthProviderProps = {
  children: React.ReactNode
}

function AuthProvider({children}: AuthProviderProps) {
  const navigate = useNavigate()

  function onRedirectCallBack(appState?: AppState) {
    navigate(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      domain={appConfig.auth.domain}
      clientId={appConfig.auth.clientId}
      audience={appConfig.auth.audience}
      scope={appConfig.auth.scope}
      redirectUri={appConfig.auth.redirectUri}
      onRedirectCallback={onRedirectCallBack}
    >
      {children}
    </Auth0Provider>
  )
}

function useClient<T, P extends object = {}>({
  protectedClient = false,
}: {
  protectedClient?: boolean
} = {}) {
  const {getAccessTokenSilently, getIdTokenClaims} = useAuth0()

  return React.useCallback(
    async (endpoint: string, config?: ConfigType<P>) =>
      client<T, P>(endpoint, {
        ...config,
        idToken: (await getIdTokenClaims())?.__raw,
        token: protectedClient ? await getAccessTokenSilently() : undefined,
      }),
    [getAccessTokenSilently, getIdTokenClaims, protectedClient],
  )
}

export {AuthProvider, useClient}
