import {ThemeProvider} from '@mui/material/styles'
import React from 'react'

import {theme as ACELight} from '@ansarada/mui-themes/lib/ace/ace-light'
import {AnsaradaLight} from '@ansarada/mui-themes/lib/ansarada'
import {theme as ESGLight} from '@ansarada/mui-themes/lib/esg/esg-light'

const themes = {
  ACELight,
  ESGLight,
  AnsaradaLight,
} as const

export function withTheme<T extends object = Record<string, never>>(
  theme: keyof typeof themes,
  WrappedComponent: React.ComponentType<T>,
) {
  const ComponentWithTheme = (props: T) => {
    return (
      <ThemeProvider theme={themes[theme]}>
        <WrappedComponent {...props} />
      </ThemeProvider>
    )
  }
  return ComponentWithTheme
}
