// Reference:
// https://ansarada.atlassian.net/wiki/spaces/G2G/pages/752451694/Colour

export const white = '#FFFFFF';

export const black = '#07070C';

export const text800 = '#07070C';

export const text500 = '#717676';

export const textBase = text800;

export const textLight = text500;

export const textWhite = white;

export const functionalGreen500 = '#018436';

export const functionalGreen400 = '#DCF3E5';

export const functionalRed600 = '#CA2B2E';

export const functionalRed600T10 = '#F9E9EA'; // Tint, 10% of functionalRed600, background color only

export const neutral600 = '#717676';

export const neutral500 = '#84898A';

export const neutral400 = '#9CA2A3';

export const neutral300 = '#B4BABB';

export const neutral200 = '#E4EBEC';

export const neutral100 = '#F4F8F8';

export const neutral50 = '#F1F1F1';

export const bloom = '#FF5C5F';

export const watermelon = '#FF9C9D';

export const citrus = '#FEB26C';

export const mandarine = '#FECB9D';

export const sunrise = '#FFE974';

export const lemon = '#FFF4B5';

export const spring = '#28DC91';

export const kiwi = '#80EDC0';

export const crystal = '#55E4D3';

export const quandong = '#AAF2EA';

export const dawn = '#5FB6FE';

export const berry = '#9DD2FE';

export const waterspout = '#94fdc0';

export const magenta = '#c399fd';

export const cyan = '#94e8fd';

export const newCitrus = '#c9fb00';
