import { AppBar, AppBarProps, styled } from '@mui/material';

import { MenuItemGroup } from '../menu';
import { ProductMenu } from './product-menu';
import { UserMenu } from './user-menu';

type PlatformHeaderProps = AppBarProps & {
  productName: string;
  userName: string;
  productMenu?: MenuItemGroup[] | null;
  userMenu?: MenuItemGroup[] | null;
  onProductMenuClick?: () => void;
};

const PlatformHeader = styled(
  ({
    productMenu,
    productName,
    userMenu,
    userName,
    onProductMenuClick,
    children,
    ...props
  }: PlatformHeaderProps) => {
    return (
      <AppBar
        {...props}
        color="primary"
        sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', px: 2 }}
      >
        <ProductMenu name={productName} menu={productMenu} onClickLogo={onProductMenuClick} />

        {children}

        <UserMenu name={userName} menu={userMenu} />
      </AppBar>
    );
  },
)(() => ({
  height: '54px',
  boxShadow: 'none',
  justifyItems: 'center',
}));

export { PlatformHeader };
export type { PlatformHeaderProps };
