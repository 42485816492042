import { TypographyStyle } from '@mui/material';

const AnsaradaFavoritFont = {
  variable: {
    file: 'AnsaradaFavoritVariable',
    name: 'Ansarada Favorit',
  },
  bold: {
    file: 'AnsaradaFavorit-Bold',
    name: 'Ansarada Favorit Bold',
  },
  'bold-italic': {
    file: 'AnsaradaFavorit-BoldItalic',
    name: 'Ansarada Favorit Bold Italic',
  },
  book: {
    file: 'AnsaradaFavorit-Book',
    name: 'Ansarada Favorit Book',
  },
  'book-italic': {
    file: 'AnsaradaFavorit-BookItalic',
    name: 'Ansarada Favorit Book Italic',
  },
  light: {
    file: 'AnsaradaFavorit-Light',
    name: 'Ansarada Favorit Light',
  },
  'light-italic': {
    file: 'AnsaradaFavorit-LightItalic',
    name: 'Ansarada Favorit Light Italic',
  },
  medium: {
    file: 'AnsaradaFavorit-Medium',
    name: 'Ansarada Favorit Medium',
  },
  'medium-italic': {
    file: 'AnsaradaFavorit-MediumItalic',
    name: 'Ansarada Favorit Medium Italic',
  },
  regular: {
    file: 'AnsaradaFavorit-Regular',
    name: 'Ansarada Favorit Regular',
  },
  'regular-italic': {
    file: 'AnsaradaFavorit-RegularItalic',
    name: 'Ansarada Favorit Regular Italic',
  },
};

const FALLBACK_FONTS = ['Aktiv Grotesk', 'Helvetica', 'Arial', 'sans-serif'];

export const ANSARADA_REGULAR_FONTS = [AnsaradaFavoritFont['regular'].name, ...FALLBACK_FONTS];

export const ANSARADA_BOLD_FONTS = [AnsaradaFavoritFont['bold'].name, ...FALLBACK_FONTS];

export const ANSARADA_VARIABLE_FONTS = [AnsaradaFavoritFont['variable'].name, ...FALLBACK_FONTS];

export function supportVariableFont(
  fallback: 'regular' | 'bold',
  settings?: TypographyStyle,
): object {
  const fallbackFonts = fallback === 'regular' ? ANSARADA_REGULAR_FONTS : ANSARADA_BOLD_FONTS;
  return {
    fontFamily: fallbackFonts.join(','),
    '@supports (font-variation-settings: normal)': {
      fontFamily: ANSARADA_VARIABLE_FONTS.join(','),
      ...(settings ?? {}),
    },
  };
}

const FontExtensionFormat = {
  woff2: 'woff2',
  woff: 'woff',
  ttf: 'truetype',
  otf: 'opentype',
};

type FontExtension = keyof typeof FontExtensionFormat;

const BASE_STATIC_URL = 'https://static.ansarada.com/fonts/ansarada-favorit';

function getAnsaradaFavoritFontUrls(filename: string, isVariableFont = false) {
  if (isVariableFont) {
    const extensions: FontExtension[] = ['woff2', 'woff', 'ttf'];

    const urls = extensions.map((extension) => {
      const url = BASE_STATIC_URL.concat(`/${filename}.${extension}`);
      return `url(${url}) format('${FontExtensionFormat[extension]}-variations')`;
    });

    return urls.join(',');
  }

  const extensions: FontExtension[] = ['woff2', 'woff', 'otf'];

  const urls = extensions.map((extension) => {
    const url = BASE_STATIC_URL.concat(`/Ansarada%20Favorit/${filename}.${extension}`);
    return `url(${url}) format('${FontExtensionFormat[extension]}')`;
  });

  return urls.join(',');
}

export function generateAnsaradaFavoritFontFace(type: keyof typeof AnsaradaFavoritFont) {
  const { file, name } = AnsaradaFavoritFont[type];

  if (type === 'variable') {
    return `
      @font-face {
        font-family: ${name};
        src: ${getAnsaradaFavoritFontUrls(file, true)};
        font-weight: 125 950;
        font-stretch: 75% 125%;
        font-style: italic underline oblique 0deg 20deg;
      }
    `;
  }

  return `
      @font-face {
        font-family: ${name};
        src: ${getAnsaradaFavoritFontUrls(file)};
      }
    `;
}
