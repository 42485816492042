import { createWebsiteTheme } from './theme';

export const AnsaradaLight = createWebsiteTheme({
  name: 'AnsaradaLight',
  mode: 'light',
});

export const AnsaradaDark = createWebsiteTheme({
  name: 'AnsaradaDark',
  mode: 'dark',
});
