import Box from '@mui/material/Box'
import * as React from 'react'

interface SignupSectionProps {
  children: React.ReactNode
}

export function SignupSection(props: SignupSectionProps) {
  return (
    <Box data-test-id="freemium-signup" display="flex" minHeight="100vh">
      <Box sx={{width: {xs: '100%', md: '60%'}, p: {xs: 5, sm: 7}}}>
        <Box maxWidth={480} margin="0 auto">
          <Box>
            <Logo />
          </Box>

          <Box py={6} sx={{height: '100%'}}>
            {props.children}
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: {xs: 'none', md: 'flex'},
          backgroundColor: 'grey.100',
          alignItems: 'center',
          justifyContent: 'center',
          width: '50%',
        }}
      >
        <ProfileIllustration />
      </Box>
    </Box>
  )
}

export function Logo() {
  return (
    <svg
      data-test-id="logo"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 151 21"
      xmlSpace="preserve"
      width="213"
      height="29"
    >
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html:
            '\n\t.st0{fill:#07070C;}\n\t.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#07070C;}\n',
        }}
      />
      <g id="Layer_2_00000010308761513612917560000005639154805658333094_">
        <g id="Layer_1-2">
          <path className="st0" d="M9.6,1.3c4.4,0,8,3.6,8,8h-8V1.3z" />
          <path
            className="st0"
            d="M9.6,9.9h8v8h-3.9c-2.2,0-4-1.8-4-4c0,0,0,0,0,0V9.9z"
          />
          <ellipse className="st0" cx={5} cy="5.2" rx={4} ry={4} />
          <path
            className="st0"
            d="M1,13.9c0-2.2,1.8-4,4-4c0,0,0,0,0,0h4v4c0,2.2-1.7,4-3.9,4C2.8,17.9,1,16.2,1,13.9C1,14,1,13.9,1,13.9z"
          />
          <polygon
            className="st0"
            points="53.7,1.2 50.5,1.2 50.4,1.2 50.4,1.3 50.4,12.9 44.2,1.3 44.2,1.2 44.1,1.2 40,1.2 39.9,1.2
			39.9,1.3 39.9,17.8 39.9,17.9 40,17.9 43.1,17.9 43.3,17.9 43.3,17.8 43.3,6.1 49.5,17.8 49.5,17.9 49.6,17.9 53.7,17.9
			53.8,17.9 53.8,17.8 53.8,1.3 53.8,1.2 		"
          />
          <path
            className="st0"
            d="M131.7,3.3c-1.5-1.4-3.6-2.1-6.1-2.1h-5.8v16.7h5.9c2.5,0,4.6-0.7,6-2.1c1.5-1.4,2.3-3.5,2.3-6.2
			S133.2,4.7,131.7,3.3z M123.2,4.3h2.1c1.6,0,2.9,0.3,3.8,1.1s1.5,2.1,1.5,4.2s-0.6,3.4-1.5,4.2c-0.9,0.8-2.2,1.1-3.8,1.1h-2.1V4.3
			z"
          />
          <path
            className="st0"
            d="M67.4,2.6c-1.1-1-2.7-1.6-4.7-1.6c-4.1,0-6.3,2.5-6.3,5c0,1.2,0.3,2.3,1,3.1c0.7,0.8,1.8,1.4,3.3,1.7l2.9,0.6
			l0,0c0.7,0.2,1.2,0.4,1.4,0.6c0.3,0.3,0.4,0.6,0.4,1.1c0,0.5-0.2,1-0.6,1.3c-0.4,0.3-1.1,0.6-1.9,0.6h-0.2c-1.2,0-2.1-0.3-2.7-0.8
			s-0.9-1.2-1-2l0-0.1h-3.4l0,0.1l0.1,0l0,0l-0.1,0c0.1,1.7,0.6,3.2,1.7,4.2c1.1,1,2.8,1.6,5.1,1.6c2.1,0,3.7-0.6,4.8-1.5
			c1.1-0.9,1.7-2.2,1.7-3.7c0-1.4-0.4-2.4-1.3-3.2c-0.8-0.8-2-1.3-3.4-1.6l-2.5-0.5c-0.7-0.1-1.2-0.3-1.5-0.6
			c-0.3-0.3-0.4-0.6-0.4-1.1c0-1,1-1.9,2.7-1.9h0.2c0.8,0,1.5,0.2,2,0.6c0.5,0.4,0.8,1,0.9,1.9l0,0.1h3.4V6.5
			C69.1,4.9,68.5,3.5,67.4,2.6z M63.7,11.4L63.7,11.4L63.7,11.4z"
          />
          <path
            className="st1"
            d="M38.5,17.7L32.2,1.3l0-0.1h-3.9l0,0.1l-6.5,16.4l-0.1,0.2h3.6l0-0.1l1.5-3.9l0.3-0.7h6.2l0.3,0.7l1.5,3.9
			l0,0.1h3.6L38.5,17.7z M30.1,5.2l1.8,4.7h-3.6L30.1,5.2z"
          />
          <path
            className="st1"
            d="M85.9,17.7L79.6,1.3l-0.1,0l0,0l0.1,0l0-0.1h-3.9l0,0.1l-6.5,16.4l-0.1,0.2h3.6l0-0.1l-0.1,0l0,0l0.1,0
			l1.5-3.9l0.3-0.7h6.2l0.3,0.7l1.5,3.9l0.1,0l0,0l-0.1,0l0,0.1H86L85.9,17.7z M79.3,9.9h-3.6l1.8-4.7L79.3,9.9z"
          />
          <path
            className="st1"
            d="M118.5,17.7l-6.3-16.4l-0.1,0l0,0l0.1,0l0-0.1h-3.9l0,0.1l-6.5,16.4l-0.1,0.2h3.6l0-0.1l-0.1,0l0,0l0.1,0
			l1.5-3.9l0.3-0.7h6.1l0.3,0.7l1.5,3.9l0.1,0l0,0l-0.1,0l0,0.1h3.6L118.5,17.7z M108.3,9.8l1.8-4.6l1.8,4.6H108.3z"
          />
          <path
            className="st1"
            d="M150,17.7l-6.3-16.4l0-0.1h-3.9l0,0.1l-6.5,16.4l-0.1,0.2h3.6l0-0.1l1.5-3.9l0.3-0.7h6.1l0.3,0.7l1.5,3.9
			l0.1,0l0,0l-0.1,0l0,0.1h3.6L150,17.7z M139.9,9.8l1.8-4.6l1.8,4.6H139.9z"
          />
          <path
            className="st1"
            d="M100.9,17.7l-2.2-5.8c1.4-1.1,2.2-2.8,2.2-4.7c0-3.3-2.7-6.1-6-6.1h-7.6v16.7h3.4v-4.6h4.9l1.7,4.5l0,0.1h3.6
			L100.9,17.7z M94,9.9L94,9.9h-3.3V4.6h4.2c1.4,0,2.6,1.2,2.6,2.7c0,1.4-1.2,2.6-2.6,2.6L94,9.9L94,9.9z"
          />
        </g>
      </g>
    </svg>
  )
}

export function ProfileIllustration() {
  return (
    <svg
      data-test-id="profile-illustration"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      id="Layer_1"
      x={0}
      y={0}
      viewBox="0 0 452 364"
      height={296}
    >
      <style>
        {
          '.st-face{fill:#d4a181}.st2{fill:#2c2c2e}.st3{fill:#343d48}.st4{fill:#fff;stroke:#e4ebec}.st5{fill:#c9fb00}.st6{stroke:#e4ebec}.st6,.st7,.st8{fill:#e4ebec}.st8{stroke:#fff}.st9{fill:#fff}.st10{fill:none;stroke:#e4ebec}.st12{fill-rule:evenodd;clip-rule:evenodd;fill:#fff}'
        }
      </style>
      <path
        d="M452 362.7H.3"
        style={{
          fill: 'none',
          stroke: '#2c2c2e',
        }}
      />
      <path
        d="m380.9 312.6 12.2 1.4-.5 9-11.3 15.3-.5-4.3.5-15.1-.4-6.3z"
        className="st-face"
      />
      <path
        d="M392.1 313.5c1.6 0 3.8-.1 7.8 2.9 0-.1-18.5 38-18.5 38s-4.7-.3-4.7-4.4c.4-3 2.9-9.1 3.7-15 6.2-4.1 8.7-8.9 11.7-21.5z"
        className="st2"
      />
      <path
        d="m310.2 343.6 4.1 11.5-8.3 3.5-18.7-3.5 3.6-2.4 13.8-6.2 5.5-2.9z"
        className="st-face"
      />
      <path
        d="M314.3 354c.7 1.4 1.7 3.4.8 8.3h-42.3s-1.8-4.4 1.9-6.2c2.8-1 9.5-1.3 15.1-3.2 6.4 3.8 11.8 4 24.5 1.1z"
        className="st2"
      />
      <path
        d="m331.8 176.8-11.9 95.1-7.5 74.3-14 3.5-6.4-172.9h39.8z"
        className="st3"
      />
      <path
        d="M343 176.8c-2.5 32.6-6.6 80.7-5.9 82.8s52.5 51.3 52.5 51.3l-8.5 11.9s-62.4-48.4-65.2-54.4c-2.9-6-15.3-59.7-21.4-91.7H343v.1z"
        className="st2"
      />
      <path d="M410.5 25.5h-366v251h366v-251z" className="st4" />
      <path
        d="M72.8 91.5c-.6 0-1.2.3-1.6.8s-.6 1.1-.6 1.7.2 1.3.6 1.7c.4.5 1 .8 1.6.8h97.4c.6 0 1.2-.3 1.6-.8.4-.5.6-1.1.6-1.7s-.2-1.3-.6-1.7c-.4-.5-1-.8-1.6-.8H72.8zM72.8 78.5c-.6 0-1.2.3-1.6.8s-.6 1.1-.6 1.7.2 1.3.6 1.7c.4.5 1 .8 1.6.8h97.4c.6 0 1.2-.3 1.6-.8.4-.5.6-1.1.6-1.7s-.2-1.3-.6-1.7c-.4-.5-1-.8-1.6-.8H72.8zM72.8 104.5c-.6 0-1.2.3-1.6.8s-.7 1.1-.7 1.7.2 1.3.7 1.7c.4.5 1 .8 1.6.8h50.4c.6 0 1.2-.3 1.6-.8s.7-1.1.7-1.7-.2-1.3-.7-1.7c-.4-.5-1-.8-1.6-.8H72.8z"
        className="st5"
      />
      <path
        d="M70.3 152.5c-.6 0-1.1.5-1.4 1-.3.6-.5 1.3-.5 2s.2 1.4.5 2c.3.5.8 1 1.4 1h138.3c.6 0 1.1-.5 1.4-1 .3-.6.5-1.3.5-2s-.2-1.4-.5-2c-.3-.5-.8-1-1.4-1H70.3zM70.3 165.5c-.6 0-1.1.5-1.4 1-.3.6-.5 1.3-.5 2s.2 1.4.5 2c.3.5.8 1 1.4 1h102.3c.6 0 1.1-.5 1.4-1 .3-.6.5-1.3.5-2s-.2-1.4-.5-2c-.3-.5-.8-1-1.4-1H70.3zM70.3 178.5c-.6 0-1 .3-1.4.8-.3.5-.5 1.1-.5 1.7s.2 1.2.5 1.7.8.8 1.4.8h138.3c.6 0 1-.3 1.4-.8.3-.5.5-1.1.5-1.7s-.2-1.2-.5-1.7-.8-.8-1.4-.8H70.3zM70.3 191.5c-.6 0-1 .3-1.4.8-.3.5-.5 1.1-.5 1.7s.2 1.2.5 1.7.8.8 1.4.8h102.3c.6 0 1-.3 1.4-.8.3-.5.5-1.1.5-1.7s-.2-1.2-.5-1.7-.8-.8-1.4-.8H70.3z"
        className="st6"
      />
      <path d="M231 55h170v178H231z" className="st7" />
      <path
        d="M314.2 94.4c-4.9 2.9-9.6 4.3-11.9 3.8-5.6-1.4-6.1-22-1.8-30.1s26.7-11.6 27.8 4.4c.4 5.6-1.9 10.5-5.4 14.6l6.2 28.9h-18l3.1-21.6z"
        className="st-face"
      />
      <path
        d="M307.7 59.3c2.5-.7 6-1 7.5-.8-.9-2-1.2-4.3-.9-6.5.3-2.2 1.2-4 2.1-5.9.4-.9.9-1.8 1.6-2.4.7-.7 1.6-1.1 2.5-1.4 1-.2 2-.2 2.9 0 .4.1.8.3 1.2.4.4.1.6-.2.9-.4.7-.6 1.8-1 2.8-1.1 1.1-.1 2.2 0 3.2.4 1 .3 1.9.9 2.6 1.7.3.4.6.8.8 1.2.1.2.1.3.3.4.2.1.4.1.5.1 1.7.6 3.2 1.9 3.9 3.6.5 1 .7 2.7.6 4.7-.1 1.9-1.8 7-3.4 8.8-1.5 1.6-3.6 2.7-5.7 3.2 1.4 1.6 2.3 3.5 3 5.5.7 2.2 1.2 4.4 1.4 6.7.2 2.2.2 4.5-.3 6.6-.5 2-1.5 3.6-3.2 4.7-.8.5-1.6.8-2.5 1.2-.8.3-1.7.6-2.6.8-1.7.3-3.6.3-4.8-1-.5-.5-1-1.1-1.3-1.7-.1-.2-.2-.3 0-.5s.5-.4.7-.6c1.3-1.1 2.1-2.5 2.3-4.3.2-1.8-.3-4-2-4.8-2.1-.9-4.4.1-6.3 1.2-.8-2.8-2.4-5.3-5-6.6-2.8-1.4-6.1-1.6-9-2.8-.8-.3-1.5-.7-2.1-1.3-.2-.3-.5-.6-.6-1-.1-.5.2-.9.4-1.2 1.3-2.2 6-6.2 8.5-6.9zM331.7 187.2l-11.9 95.1-7.5 74.3-14 3.5-6.4-172.9h39.8z"
        className="st3"
      />
      <path
        d="M342.9 187.2c-2.5 32.6-6.6 80.7-5.9 82.8s52.5 51.3 52.5 51.3l-8.5 11.9s-62.4-48.4-65.2-54.4c-2.9-6-15.3-59.7-21.4-91.7h48.5v.1z"
        className="st2"
      />
      <path
        d="M314.2 94.4c-4.9 2.9-9.6 4.3-11.9 3.8-5.6-1.4-6.1-22-1.8-30.1 4.3-8.1 26.7-11.6 27.8 4.4.4 5.6-1.9 10.5-5.4 14.6l6.2 28.9h-18l3.1-21.6z"
        className="st-face"
      />
      <path
        d="M307.7 59.3c2.5-.7 6-1 7.5-.8-.9-2-1.2-4.3-.9-6.5.3-2.2 1.2-4 2.1-5.9.4-.9.9-1.8 1.6-2.4.7-.7 1.6-1.1 2.5-1.4 1-.2 2-.2 2.9 0 .4.1.8.3 1.2.4.4.1.6-.2.9-.4.7-.6 1.8-1 2.8-1.1 1.1-.1 2.2 0 3.2.4 1 .3 1.9.9 2.6 1.7.3.4.6.8.8 1.2.1.2.1.3.3.4.2.1.4.1.5.1 1.7.6 3.2 1.9 3.9 3.6.5 1 .7 2.7.6 4.7-.1 1.9-1.8 7-3.4 8.8-1.5 1.6-3.6 2.7-5.7 3.2 1.4 1.6 2.3 3.5 3 5.5.7 2.2 1.2 4.4 1.4 6.7.2 2.2.2 4.5-.3 6.6-.5 2-1.5 3.6-3.2 4.7-.8.5-1.6.8-2.5 1.2-.8.3-1.7.6-2.5.8-1.7.3-3.6.3-4.8-1-.5-.5-1-1.1-1.3-1.7-.1-.2-.2-.3 0-.5s.5-.4.7-.6c1.3-1.1 2.1-2.5 2.3-4.3.2-1.8-.3-4-2-4.8-2.1-.9-4.4.1-6.3 1.2-.8-2.8-2.4-5.3-5-6.6-2.8-1.4-6.1-1.6-9-2.8-.8-.3-1.5-.7-2.1-1.3-.2-.3-.5-.6-.6-1-.1-.5.2-.9.4-1.2 1.2-2.2 5.9-6.2 8.4-6.9z"
        className="st3"
      />
      <path
        d="M315 58.3c0 .5 5.1 5.4 16.5 6.7-1.9-5.4-16.5-7.2-16.5-6.7z"
        className="st5"
      />
      <path
        d="m241.6 98 29.7 6.7-6.6 12.9-23.6-11.7c-10.6.1-15.7-.6-15.2-2.1.4-1.3 1.6-1.6 2.6-1.8.8-.2 1.6-.4 1.9-1 .7-1.4-2.4-4.8-5.5-7.9s-.8-3.4.6-3.2c5.1 1.5 10.4 4.2 16.1 8.1zm8.1 78.2 24.5-18 5.4 13.4-24.3 10.1c-6.9 8.1-10.7 11.4-11.5 10.1-.7-1.1-.1-2.2.3-3.2.4-.8.7-1.5.5-2.1-.6-1.5-5.2-1.3-9.6-1-4.4.3-3.1-1.6-2-2.6 4.5-2.6 10-4.9 16.7-6.7z"
        className="st-face"
      />
      <path
        d="M312.4 109.6c-13.4 24.9-32.7 44.1-60.7 63.9l10.6 12c31.3-11.6 47.8-18.7 55.9-42.2 2-5.9 3.5-24.8 4.7-34.7l-10.5 1z"
        className="st8"
      />
      <path
        d="M308.1 107.5c2.9 15.4-25.7 84.8-25.7 84.8h38.1l3.8-85.5-16.2.7z"
        className="st8"
      />
      <path
        d="M336.2 192.7h-45.7s20.1-61.4 20.6-84.3c0-.8 1.5-2.2 2.1-2 7 2 14.9-1.5 14.9-1.5 14 22.7 5.3 50.5 8.1 87.8z"
        className="st5"
      />
      <path
        d="M320.3 125.1c-4.8 35.9-10.7 82-10.7 82h38.2c-14.6-34.1-18.3-72.2-20.1-90.8-.7-7.2-1.1-11.4-1.8-11.4H323c-2.8 0-5.8.1-9.1.2-17 .6-40.6 1.3-67.2-7.2l-2.3 11.6c29.9 12.4 49.3 15.3 75.9 15.6z"
        className="st9"
      />
      <path
        d="M309.5 207.1v.5h-.5l.1-.6.4.1zm10.8-82v-.5h.6l-.1.6-.5-.1zm27.5 82 .5-.2.3.7h-.8v-.5zm-20.1-90.7h-.5.5zm-4.7-11.5-.5-.1.1-.4h.4v.5zm0 .1.5.1-.1.4h-.4v-.5zm-9.1.2v-.5.5zM246.7 98l-.5-.1.1-.6.5.2-.1.5zm-2.3 11.6-.2.5-.4-.2.1-.4.5.1zm65.1 97.5-.5-.1v-.5c0-.3.1-.9.2-1.5.2-1.3.4-3.3.7-5.7.6-4.9 1.5-11.8 2.6-19.8 2.1-16 4.8-36.5 7.2-54.5l1 .1c-2.4 18-5.1 38.5-7.2 54.5-1 8-1.9 14.9-2.6 19.8-.3 2.4-.6 4.4-.7 5.7-.1.7-.2 1.2-.2 1.5 0 .2 0 .3-.1.4v.1c.1.1.1.1-.4 0zm38.3.5h-38.2v-1h38.2v1zm-19.6-91.3c1.8 18.6 5.5 56.6 20.1 90.6l-.9.4c-14.7-34.1-18.3-72.3-20.1-90.9l.9-.1zm-2.3-11.9c.4 0 .6.3.7.4.1.2.2.4.3.7.1.5.3 1.3.4 2.3.3 2 .5 4.8.9 8.4l-1 .1c-.3-3.6-.6-6.4-.9-8.4-.1-1-.3-1.7-.4-2.2-.1-.2-.1-.4-.2-.5s0 .1.2.1v-.9zm-2.9 0h2.9v1H323v-1zm-.5.5 1 .1-1-.1zm.5.6c-2.8 0-5.8.1-9.1.2v-1c3.3-.1 6.3-.2 9.1-.2v1zm-9.1.2c-17 .6-40.7 1.4-67.4-7.2l.3-1c26.5 8.5 50.1 7.7 67.1 7.2v1zm-66.7-7.6-2.3 11.6-1-.2 2.3-11.6 1 .2zm-2.6 11c29.8 12.3 49.2 15.2 75.7 15.5v1c-26.6-.3-46.1-3.2-76.1-15.5l.4-1z"
        className="st7"
      />
      <path
        d="m321.8 105.2-4.3 12.4-8 91 40.3.4s-9.7-26.5-12.4-45.8c-2.7-19.4 1.6-25.6 0-36.8s-5.2-21.5-5.2-21.5l-10.4.3z"
        className="st4"
      />
      <path
        d="M322.5 104.9s1.6 11.2.8 19c-.1.9-2.1 1.2-2.1 1.5-.1.9 1.5 3.1 1.4 4.1-1.6 10.2-5.1 31.7-9.8 48.9.1-.1 3-60.9 3-60.9l2-12.6h4.7z"
        className="st4"
      />
      <path d="m321.1 192.7-1.3-4.2 17.6 3.6" className="st10" />
      <path d="M320.5 112.6h8.5v22.3h-8.5z" className="st9" />
      <path
        d="M328.6 104.9s-58.7 2.6-82-6.9c0 .1-2.3 11.6-2.3 11.6s36.6 23.6 80.4 25.6"
        className="st9"
      />
      <path
        d="M328.6 104.9s-58.7 2.6-82-6.9c0 .1-2.3 11.6-2.3 11.6s36.6 23.6 80.4 25.6"
        className="st10"
      />
      <path
        d="M44 0h367.4v25.5H44z"
        style={{
          fill: '#07070c',
        }}
      />
      <g id="Layer_2_00000067206080070435457790000010536304036768902840_">
        <g id="Layer_1-2">
          <path
            d="M56.8 8.3c2.1 0 3.8 1.7 3.8 3.8h-3.8V8.3zM56.8 12.4h3.8v3.8h-1.9c-1.1 0-1.9-.9-1.9-1.9v-1.9z"
            className="st5"
          />
          <circle cx={54.6} cy={10.2} r={1.9} className="st5" />
          <path
            d="M52.7 14.4c0-1.1.9-1.9 1.9-1.9h1.9v1.9c0 1.1-.8 1.9-1.9 1.9s-1.9-.8-1.9-1.9z"
            className="st5"
          />
          <path
            d="M78.1 8.2h-1.6V13.9l-3-5.6v-.1h-2.1v8.1H73.1V10.6l3 5.7h2.1V8.2zM115.8 9.3c-.7-.7-1.7-1-2.9-1h-2.8v8.1h2.8c1.2 0 2.2-.3 2.9-1 .7-.7 1.1-1.7 1.1-3s-.4-2.5-1.1-3.1zm-4.1.4h1c.8 0 1.4.2 1.8.5.4.4.7 1 .7 2s-.3 1.6-.7 2c-.4.4-1.1.5-1.8.5h-1v-5zM84.8 8.9c-.5-.5-1.3-.8-2.3-.8-2 0-3.1 1.2-3.1 2.4 0 .6.2 1.1.5 1.5s.9.7 1.6.8l1.4.3c.3.1.6.2.7.3s.2.3.2.5-.1.5-.3.6c-.2.2-.5.3-.9.3h-.1c-.6 0-1-.2-1.3-.4s-.4-.6-.5-1v-.1h-1.6v.1h.1-.1c0 .8.3 1.5.8 2s1.4.8 2.5.8c1 0 1.8-.3 2.3-.7s.8-1.1.8-1.8-.2-1.2-.6-1.6c-.4-.4-1-.6-1.6-.8l-1.2-.2c-.3-.1-.6-.2-.7-.3s-.2-.3-.2-.6c0-.5.5-.9 1.3-.9h.1c.4 0 .7.1 1 .3.2.2.4.5.4.9v.1h1.6v-.1c-.1-.5-.3-1.1-.8-1.6zm-1.9 4.3z"
            className="st9"
          />
          <path
            d="m70.8 16.2-3.1-7.9h-1.9l-3.1 7.9v.1h1.7l.7-1.9.1-.3h3l.1.3.7 1.9 1.8-.1zm-4.1-6.1.9 2.3h-1.7l.8-2.3zM93.7 16.2l-3.1-7.9h-.1.1-1.9l-3.1 7.9v.1h1.7-.1.1l.7-1.9.1-.3h3l.1.3.7 1.9h.1-.1l1.8-.1zm-3.2-3.8h-1.7l.9-2.3.8 2.3zM109.4 16.2l-3.1-7.9h-.1.1-1.9l-3.1 7.9v.1h1.7-.1.1l.7-1.9.1-.4h3l.1.4.7 1.9h.1-.1l1.8-.1zm-4.9-3.8.9-2.2.9 2.2h-1.8zM124.6 16.2l-3-7.9h-1.9l-3.1 7.9v.1h1.7l.7-1.9.1-.4h3l.1.4.7 1.9h.1-.1l1.7-.1zm-4.9-3.8.9-2.2.9 2.2h-1.8zM100.9 16.2l-1.1-2.8c.7-.5 1.1-1.4 1.1-2.2 0-1.6-1.3-2.9-2.9-2.9h-3.7v8.1h1.6v-2.2h2.4l.8 2.2 1.8-.2zm-3.3-3.8H96V9.9h2c.7 0 1.3.6 1.3 1.3s-.6 1.3-1.3 1.3l-.4-.1z"
            className="st12"
          />
        </g>
      </g>
    </svg>
  )
}
