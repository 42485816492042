import {ApolloProvider} from '@apollo/client'
import {useAuth0} from '@auth0/auth0-react'
import React, {useState} from 'react'

import {getOrCreateClient} from '~/lib/apollo/client'

export function ApolloClientProvider({children}: React.PropsWithChildren) {
  const {getAccessTokenSilently} = useAuth0()
  const [apolloClient] = useState(() =>
    getOrCreateClient({
      uri: `${process.env.ONE_GATEWAY_BASE_URL}/graphql`,
      getAccessToken: getAccessTokenSilently,
    }),
  )

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}
