import {useEffect} from 'react'

import {Progress} from '~/components/progress'
import {MessageSection} from '~/components/sections/message-section'
import {useClient} from '~/context/auth-context'
import type {CompleteProfileDto} from '~/types'

interface Props {
  assetCallbackUrl: string
  signupToken: string
}

export const SignupComplete = ({assetCallbackUrl, signupToken}: Props) => {
  const client = useClient<
    void,
    Pick<CompleteProfileDto, 'signupToken' | 'isEmailVerified'>
  >()

  useEffect(() => {
    const updateProfile = async () => {
      await client('signup/v1/profiles/update', {
        data: {
          signupToken,
          isEmailVerified: true,
        },
        method: 'PATCH',
      })
      window.location.href = assetCallbackUrl
    }
    updateProfile()
  }, [assetCallbackUrl, signupToken, client])

  return <MessageSection title={<Progress />} />
}
