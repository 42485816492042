import {gql, useMutation} from '@apollo/client'

import {fragmentRegistry} from '~/lib/apollo/fragmentRegistry'
import type {
  UpdateProfileMutation,
  UpdateProfileMutationVariables,
} from '~/types/api'

const UPDATE_PROFILE_MUTATION = gql`
  mutation UpdateProfileMutation($input: UpdateMyProfileInput!) {
    updateMyProfile(input: $input) {
      userProfile {
        ...UserProfileFragment
      }
      errors {
        ... on InvalidProfileInput {
          message
          fieldErrorsJson
        }
      }
    }
  }
`

fragmentRegistry.register(gql`
  fragment UserProfileFragment on UserProfile {
    id
    email
    firstName
    lastName
    phoneNumber
    companyName
    termsAndConditionsAccepted
    isSso
    signUpCompleted
    jobTitle {
      groupName
      internalId
      name
    }
    location {
      internalId
      name
    }
  }
`)

export const useUpdateProfileMutation = () => {
  return useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(
    UPDATE_PROFILE_MUTATION,
  )
}
