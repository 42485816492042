import { Box, Typography, styled } from '@mui/material';
import { PropsWithChildren } from 'react';

import * as uiColorsV1 from '@ansarada/colors/lib/design-system-colors/v1';

const GroupHeader = styled(({ children, ...props }: PropsWithChildren) => {
  return (
    <Box {...props}>
      <Typography variant="overline">{children}</Typography>
    </Box>
  );
})(({ theme: { spacing } }) => ({
  padding: spacing(3, 2),
  height: '40px',
  color: uiColorsV1.grey._600,
}));

GroupHeader.displayName = 'GroupHeader';
export { GroupHeader };
export default GroupHeader;
