import { PaletteOptions } from '@mui/material';

import { ColorFromPalette } from '@ansarada/colors/index';
import * as uiColorsV1 from '@ansarada/colors/lib/design-system-colors/v1';

import { ThemeMode } from '../types';

declare module '@mui/material/styles/createPalette' {
  export interface Palette {
    ansarada: ColorFromPalette;
    order: ColorFromPalette;
    chaos: ColorFromPalette;

    _text: {
      base: ColorFromPalette;
      light: ColorFromPalette;
      dark: ColorFromPalette;
      white: ColorFromPalette;
    };

    _grey: {
      _100: ColorFromPalette;
      _200: ColorFromPalette;
      _300: ColorFromPalette;
      _400: ColorFromPalette;
      _500: ColorFromPalette;
      _600: ColorFromPalette;
      _700: ColorFromPalette;
    };

    mercury: {
      _100: ColorFromPalette;
      _200: ColorFromPalette;
      _300: ColorFromPalette;
      _400: ColorFromPalette;
      _500: ColorFromPalette;
      _600: ColorFromPalette;
      _700: ColorFromPalette;
    };

    chlorine: {
      _100: ColorFromPalette;
      _200: ColorFromPalette;
      _300: ColorFromPalette;
      _400: ColorFromPalette;
      _500: ColorFromPalette;
      _600: ColorFromPalette;
      _700: ColorFromPalette;
    };

    krypton: {
      _100: ColorFromPalette;
      _200: ColorFromPalette;
      _300: ColorFromPalette;
      _400: ColorFromPalette;
      _500: ColorFromPalette;
      _600: ColorFromPalette;
      _700: ColorFromPalette;
    };

    citrus: {
      _100: ColorFromPalette;
      _200: ColorFromPalette;
      _300: ColorFromPalette;
      _400: ColorFromPalette;
      _500: ColorFromPalette;
      _600: ColorFromPalette;
      _700: ColorFromPalette;
    };

    helium: {
      _100: ColorFromPalette;
      _200: ColorFromPalette;
      _300: ColorFromPalette;
      _400: ColorFromPalette;
      _500: ColorFromPalette;
      _600: ColorFromPalette;
      _700: ColorFromPalette;
    };

    bromine: {
      _100: ColorFromPalette;
      _200: ColorFromPalette;
      _300: ColorFromPalette;
      _400: ColorFromPalette;
      _500: ColorFromPalette;
      _600: ColorFromPalette;
      _700: ColorFromPalette;
    };

    neon: {
      _100: ColorFromPalette;
      _200: ColorFromPalette;
      _300: ColorFromPalette;
      _400: ColorFromPalette;
      _500: ColorFromPalette;
      _600: ColorFromPalette;
      _700: ColorFromPalette;
    };

    radon: {
      _100: ColorFromPalette;
      _200: ColorFromPalette;
      _300: ColorFromPalette;
      _400: ColorFromPalette;
      _500: ColorFromPalette;
      _600: ColorFromPalette;
      _700: ColorFromPalette;
    };

    argon: {
      _100: ColorFromPalette;
      _200: ColorFromPalette;
      _300: ColorFromPalette;
      _400: ColorFromPalette;
      _500: ColorFromPalette;
      _600: ColorFromPalette;
      _700: ColorFromPalette;
    };
  }
}

export function createPalette(mode: ThemeMode): PaletteOptions {
  return {
    ...uiColorsV1,
    // @ts-expect-error
    _text: uiColorsV1.text,
    _grey: uiColorsV1.grey,

    // ________________

    error: {
      main: uiColorsV1.neon._400,
      light: uiColorsV1.neon._300,
      dark: uiColorsV1.neon._500,
    },
    warning: {
      main: uiColorsV1.bromine._400,
      light: uiColorsV1.bromine._300,
      dark: uiColorsV1.bromine._500,
    },
    success: {
      main: uiColorsV1.krypton._400,
      light: uiColorsV1.krypton._300,
      dark: uiColorsV1.krypton._500,
    },
    info: {
      main: uiColorsV1.mercury._400,
      light: uiColorsV1.mercury._300,
      dark: uiColorsV1.mercury._500,
    },
    text: {
      primary: uiColorsV1.grey._700,
      secondary: uiColorsV1.grey._600,
      disabled: uiColorsV1.grey._500,
    },
    background: {
      default: uiColorsV1.order,
      paper: uiColorsV1.order,
    },
    mode,
    common: {
      black: uiColorsV1.chaos,
      white: uiColorsV1.order,
    },
    grey: {
      100: uiColorsV1.grey._100,
      200: uiColorsV1.grey._200,
      300: uiColorsV1.grey._300,
      400: uiColorsV1.grey._400,
      500: uiColorsV1.grey._500,
      600: uiColorsV1.grey._600,
      700: uiColorsV1.grey._700,
    },
    divider: uiColorsV1.grey._200,
  };
}
