import { Button, ButtonProps } from '@mui/material';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { ReactElement } from 'react';

import { ChevronExpanded } from '@ansarada/icons/lib/chevron-expanded';

import { Menu, MenuItemGroup, MenuList } from '../menu';

interface HeaderMenuProps {
  id: string;
  startIcon?: ReactElement;
  label: ReactElement | string;
  menu?: MenuItemGroup[] | null;
  ButtonProps?: ButtonProps;
}

const HeaderMenu = ({ id, startIcon, menu, label, ButtonProps }: HeaderMenuProps) => {
  const shouldShowMenu = menu && !!menu.length;

  return (
    <PopupState variant="popover" popupId={id}>
      {(popupState) => {
        const triggerProps = bindTrigger(popupState);
        return (
          <>
            <Button
              {...triggerProps}
              onClick={(e) => {
                e.preventDefault();
                triggerProps.onClick(e);
              }}
              startIcon={startIcon}
              endIcon={shouldShowMenu ? <ChevronExpanded size="Small" color="white" /> : null}
              color="inherit"
              disableRipple
              {...ButtonProps}
            >
              {label}
            </Button>

            {shouldShowMenu && (
              <Menu
                {...bindMenu(popupState)}
                PaperProps={{ sx: { minWidth: '220px', maxWidth: '500px' } }}
              >
                <MenuList groups={menu} popup={popupState} />
              </Menu>
            )}
          </>
        );
      }}
    </PopupState>
  );
};

export { HeaderMenu };
