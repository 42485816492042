import * as Yup from 'yup';

import { PasswordPolicy, PasswordPolicyLevel, passwordPolices } from './password-policies';

declare module 'yup' {
  interface StringSchema {
    password(level?: PasswordPolicyLevel): StringSchema;
  }
}

Yup.addMethod(Yup.string, 'password', function password(level: PasswordPolicyLevel = 'good') {
  return this.test({
    message: 'Password must match requirements',
    name: 'password',
    test: (value: string | undefined) => {
      if (!value) return false;

      const passwordPolicy = new PasswordPolicy(passwordPolices[level]);
      return passwordPolicy.check(value);
    },
  });
});
