import type {DefaultOptions} from '@tanstack/react-query'
import {QueryClient} from '@tanstack/react-query'

const queryConfig: DefaultOptions = {
  queries: {
    useErrorBoundary: true,
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 10 * 2000,
  },
}

export const queryClient = new QueryClient({defaultOptions: queryConfig})
