import type {Callback, EventProperties} from '@segment/analytics-next'

import {segment} from './segment'

export const track = (
  event: string,
  properties?: EventProperties | Callback | undefined,
): void => {
  segment(s => s.track(event, properties))
}

export function identify(profileId: string, email: string) {
  segment(segment => {
    segment.identify(profileId, {email})
  })
}
