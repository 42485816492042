import { Icon, SvgProps } from './icon';

export const LogomarkAnsarada = (props: SvgProps) => (
  <Icon {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 24 24"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            d="M168.48-183.72a2.31,2.31,0,0,1-2.32-2.28,2.3,2.3,0,0,1,2.32-2.29h2.31V-186a2.3,2.3,0,0,1-2.31,2.28"
            fill="none"
            clipRule="evenodd"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <path
            d="M171.21-188.71v-4.57a4.49,4.49,0,0,1,4.63,4.57Z"
            fill="none"
            clipRule="evenodd"
          />
        </clipPath>
        <clipPath id="clip-path-3">
          <path
            d="M173.61-183.72a2.28,2.28,0,0,1-2.4-2.23v-2.34h4.63v4.57Z"
            fill="none"
            clipRule="evenodd"
          />
        </clipPath>
        <clipPath id="clip-path-4">
          <path
            d="M170.79-191a2.3,2.3,0,0,1-2.32,2.29,2.29,2.29,0,0,1-2.31-2.29,2.29,2.29,0,0,1,2.31-2.28,2.3,2.3,0,0,1,2.32,2.28"
            fill="none"
            clipRule="evenodd"
          />
        </clipPath>
      </defs>
      <g id="Layer_6" data-name="Layer 6">
        <path d="M6.8,22A4.65,4.65,0,0,1,2,17.41a4.75,4.75,0,0,1,4.8-4.76h4.61v4.76A4.51,4.51,0,0,1,6.8,22Z" />
        <path d="M12.71,11.22V2A9,9,0,0,1,22,11.22Z" />
        <path d="M17.52,22c-2.66,0-4.81-1.75-4.81-4.37v-5H22V22Z" />
        <circle cx="6.7" cy="6.69" r="4.69" />
      </g>
    </svg>
  </Icon>
);
