import { ChevronRightRounded } from '@mui/icons-material';
import {
  Box,
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
  SxProps,
  Typography,
  formHelperTextClasses,
  selectClasses,
  styled,
  useTheme,
} from '@mui/material';
import {
  ComponentProps,
  MouseEvent,
  ReactElement,
  ReactNode,
  forwardRef,
  useCallback,
  useId,
  useMemo,
  useRef,
} from 'react';

import * as uiColorsV1 from '@ansarada/colors/lib/design-system-colors/v1';

import { UtilityProps } from '../../utils/prop';
import { mergeRefs } from '../../utils/ref';
import { Spinner } from '../Spinner';
import ClearIcon from './internal/ClearIcon';
import { getChildrenLabels, transformChildrenArray } from './utils';

type TextFieldProps = {
  /** The label of the component */
  label?: ReactNode;

  /** If `true`, show `None` option in the dropdown of `Select` mode */
  noneOption?: boolean;

  /** The icon to be displayed at the end of input */
  icon?: ReactNode;

  /** If `true`, the icon is marked as active */
  iconActive?: boolean;

  /** The function runs when clicking on icon */
  onIconClick?: (e: MouseEvent<HTMLButtonElement>) => void;

  /** If `true`, the component will turn into erroneous state */
  error?: boolean;

  /** The message to be displayed when being in erroneous state */
  errorMessage?: ReactNode;

  /** If `true`, the component will turn into loading state */
  loading?: boolean;

  /** If `true`, a clear button will be displayed at the end of input allowing clearing the value of the component */
  clearable?: boolean;

  /** The function runs when clear button is clicked */
  onClear?: () => void;
} & UtilityProps;

type Props = TextFieldProps & Omit<ComponentProps<typeof MuiTextField>, '' | keyof TextFieldProps>;

type InputRefProps = HTMLInputElement & { node?: { offsetWidth: number } };

/** This component inherits [MUI TextField's API](https://mui.com/material-ui/api/text-field/)\
 * See the [API documented on Storybook](https://ansarada-design-system.vercel.app/?path=/story/elements-form-text--single-line)
 */
const TextField = styled(
  forwardRef<HTMLDivElement, Props>(
    (
      {
        label,
        icon,
        className,
        error,
        errorMessage,
        noneOption,
        clearable,
        loading = false,
        onIconClick,
        onClear,
        ...props
      }: Props,
      ref,
    ) => {
      const inputRef = useRef<InputRefProps>(null);
      const labelRef = useRef<HTMLLabelElement>(null);

      const {
        shape: { borderRadius },
        spacing,
      } = useTheme();

      const generatedId = useId();
      const id = props.id || generatedId;

      const selectChildren = useMemo(
        () =>
          transformChildrenArray(
            props.children as ReactElement,
            props['data-ansarada-ccd'],
            noneOption,
          ),
        [props['data-ansarada-ccd'], noneOption, props.children],
      );

      const labels = useMemo(() => getChildrenLabels(selectChildren), [selectChildren]);

      const focusOnClear = useCallback(() => {
        onClear?.();
        inputRef.current?.focus();
      }, [onClear]);

      const inputEndAdornmentFromRenderProps = props?.InputProps?.endAdornment;

      const inputEndAdornment = useMemo(() => {
        if (loading) {
          return <Spinner size={16} />;
        }

        if (props.select) {
          return undefined;
        }

        if (clearable && icon) {
          return (
            <>
              <ClearIcon onClick={focusOnClear} />
              <InputAdornment position="end">
                <IconButton
                  disabled={props.disabled}
                  className="button-icon"
                  disableRipple
                  onClick={onIconClick}
                >
                  {icon}
                </IconButton>
              </InputAdornment>
            </>
          );
        }

        if (clearable) {
          return <ClearIcon onClick={focusOnClear} />;
        }

        if (icon) {
          return (
            <InputAdornment position="end">
              <IconButton
                disabled={props.disabled}
                className="button-icon"
                disableRipple
                onClick={onIconClick}
              >
                {icon}
              </IconButton>
            </InputAdornment>
          );
        }

        return inputEndAdornmentFromRenderProps;
      }, [
        loading,
        props.select,
        props.disabled,
        clearable,
        icon,
        focusOnClear,
        onIconClick,
        inputEndAdornmentFromRenderProps,
      ]);

      return (
        <Box className={className}>
          {(label || errorMessage) && (
            <Typography
              width="100%"
              component="label"
              variant="caption"
              noWrap
              htmlFor={id}
              ref={labelRef}
            >
              {error ? errorMessage || label : label}
            </Typography>
          )}

          <MuiTextField
            {...props}
            variant={props.variant}
            id={id}
            label={null}
            children={props.select ? selectChildren : props.children}
            ref={ref}
            error={error || false}
            InputProps={{
              ...(props.InputProps || {}),
              endAdornment: inputEndAdornment,
              inputRef: mergeRefs(inputRef, props.InputProps?.inputRef, props.inputRef),
              // To stop the overlap on the arrow
              sx: { pr: props.select ? '18px' : undefined },
            }}
            SelectProps={{
              ...(props.SelectProps || {}),
              IconComponent: ({ className }) =>
                loading ? null : (
                  <ChevronRightRounded
                    className={className}
                    sx={{
                      color: props.disabled ? uiColorsV1.grey._500 : uiColorsV1.chaos,
                      rotate: '90deg',

                      [`&.${selectClasses.iconOpen}`]: {
                        transform: 'none !important',
                      },
                    }}
                  />
                ),
              MenuProps: {
                ...(props.SelectProps?.MenuProps || {}),
                PaperProps: {
                  sx: {
                    marginTop: 2,
                    boxShadow: `inset 0px 0px 0px 1px ${uiColorsV1.grey._600} !important`,
                    padding: spacing(3),
                    borderRadius: `${borderRadius}px !important`,
                  },
                },
              },

              // Workaround, since MuiSelect has no placeholder
              ...(!!props.placeholder && {
                displayEmpty: true,
                renderValue: (v) => {
                  const value = props.value ?? v;

                  if (typeof value === 'string' && Boolean(value)) {
                    return labels.find((item) => item?.value === value)?.label;
                  }

                  if (Array.isArray(value)) {
                    return value
                      .map((value) => labels.find((item) => item?.value === value)?.label)
                      .join(', ');
                  }

                  return <span className="placeholder">{props.placeholder}</span>;
                },
              }),
            }}
          />
        </Box>
      );
    },
  ),
)(({
  disabled,
  multiline,
  select,
  icon,
  error,
  InputProps,
  theme: {
    shape: { borderRadius },
    spacing,
  },
}) => {
  const { readOnly } = InputProps || {};

  return {
    display: 'flex',
    flexDirection: 'column',

    [`.${formHelperTextClasses.root}`]: {
      marginInline: '0px',
      color: `${uiColorsV1.grey._700} !important`,
    },

    '& .MuiInputBase-root': {
      minHeight: '40px !important',
      color: uiColorsV1.grey._700,
    },

    ...(multiline && {
      '& .MuiInputBase-root': {
        padding: '0px !important',
        height: 'unset !important',
      },
    }),

    ...(icon && {
      '& .MuiInputBase-root': {
        paddingRight: '0px !important',
      },
    }),

    '& input, & textarea': {
      padding: spacing(2, 3),
      fontWeight: 350,
      color: uiColorsV1.grey._700,

      ...(disabled && {
        color: `${uiColorsV1.grey._600} !important`,
        WebkitTextFillColor: `${uiColorsV1.grey._600} !important`,
      }),

      '&::placeholder': {
        color: `${uiColorsV1.grey._600} !important`,
        opacity: 1,
        fontWeight: 350,

        ...(disabled && {
          color: '#a3a4a0 !important',
        }),
      },
    } as SxProps,

    '& label': {
      fontSize: '12px',
      display: 'inline-block',
      color: uiColorsV1.grey._700,

      ...(error && {
        color: uiColorsV1.neon._400,
        WebkitTextFillColor: `${uiColorsV1.neon._400} !important`,
        opacity: 1,
      }),

      ...(disabled && {
        color: '#a3a4a0 !important',
        WebkitTextFillColor: '#a3a4a0 !important',
        opacity: 1,
      }),
    } as SxProps,

    '& fieldset': {
      border: 'unset !important',
      boxShadow: 'inset 0 0 0 1px #949690',
      borderRadius: borderRadius / 2,

      ...(error && {
        boxShadow: `inset 0 0 0 1px ${uiColorsV1.neon._400}`,
      }),

      ...(disabled && {
        boxShadow: 'inset 0 0 0 1px #cac9c9',
      }),
    } as SxProps,

    ...(!disabled &&
      !readOnly && {
        '& .MuiInputBase-root:hover fieldset, & .Mui-focused fieldset': {
          boxShadow: 'inset 0 0 0 1px #07070c !important',
        },
      }),

    ...(!disabled &&
      error && {
        '& .MuiInputBase-root:hover fieldset, & .Mui-focused fieldset': {
          boxShadow: 'inset 0 0 0 2px #fb016a !important',
        },
      }),

    '& .button-icon': {
      borderRadius: 'unset !important',
      height: '39px !important',
      aspectRatio: 1,
      color: '#8e9089',
      transform: 'translateY(0.2px)',

      '&:hover': {
        color: '#07070c',
      },

      '&:disabled': {
        color: '#C4C2C2',
      },
    } as SxProps,

    ...(select && {
      '& .MuiSelect-select': {
        padding: `${spacing(2, 3)} !important`,
        fontWeight: 350,

        ...(disabled && {
          WebkitTextFillColor: '#8e9089 !important',
          color: '#8e9089 !important',
        }),
      },

      '& .placeholder': {
        color: '#8e9089 !important',
        opacity: 1,

        ...(disabled && {
          color: '#a3a4a0 !important',
        }),
      },

      '& > div svg': {
        top: 'unset !important',
      },
    }),
  };
});

export { TextField };
