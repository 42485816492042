import type {Arrayable, Nullable} from '~/types'

export function uniq<T>(array: readonly T[]): T[] {
  return Array.from(new Set(array))
}

export function toArray<T>(array?: Nullable<Arrayable<T>>): Array<T> {
  array = array ?? []
  return Array.isArray(array) ? array : [array]
}

export function mergeArrayable<T>(...args: Nullable<Arrayable<T>>[]): Array<T> {
  return args.flatMap(i => toArray(i))
}
