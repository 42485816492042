import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  SxProps,
  buttonClasses,
  circularProgressClasses,
  styled,
} from '@mui/material';
import cls from 'classnames';
import { forwardRef } from 'react';

import { UtilityProps } from '../../utils/prop';
import { Spinner } from '../Spinner';

export enum ButtonVariant {
  CAUTIOUS = 'cautious',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  LINK = 'link',
}

export enum ButtonSize {
  SUPER = 'super',
  LARGE = 'large',
  REGULAR = 'regular',
  COMPACT = 'compact',
}

type BaseProps = {
  /** The variant of Button */
  variant?: ButtonVariant | `${ButtonVariant}`;

  /** The size of Button */
  size?: ButtonSize | `${ButtonSize}`;

  /** If `true`, Button will enter loading state */
  loading?: boolean;
} & UtilityProps;

type Props = BaseProps & Omit<MuiButtonProps, keyof BaseProps | 'color' | 'endIcon'>;

const classes = {
  primary: 'Button--primary',
  secondary: 'Button--secondary',
  tertiary: 'Button--tertiary',
  link: 'Button--link',
  cautious: 'Button--cautious',

  super: 'Button--super',
  large: 'Button--large',
  regular: 'Button--regular',
  compact: 'Button--compact',

  loading: 'Button--loading',
};

/** This component inherits [MUI Button's API](https://mui.com/material-ui/api/button/)\
 * See the [API documented on Storybook](https://ansarada-design-system.vercel.app/?path=/docs/elements-button-props--documentation)
 */
const Button = styled(
  forwardRef<HTMLButtonElement, Props>(
    (
      {
        children,
        loading = false,
        startIcon: startIconProp,
        size = ButtonSize.REGULAR,
        variant = ButtonVariant.PRIMARY,
        ...props
      }: Props,
      ref,
    ) => {
      const startIcon = loading ? undefined : startIconProp;

      return (
        <MuiButton
          {...props}
          variant={variant}
          startIcon={startIcon}
          ref={ref}
          className={cls([
            props.className,
            classes[variant],
            classes[size],
            loading && classes.loading,
          ])}
        >
          {loading && <Spinner thickness={2} size={20} />}
          {!loading && children}
        </MuiButton>
      );
    },
  ),
)(({ theme: { palette, spacing } }) => {
  return {
    '&': {
      height: '36px',

      [`&.${classes.loading}`]: {
        padding: `${spacing(2, 4)} !important`,

        [`.${circularProgressClasses.root}`]: {
          color: palette._grey._600,
        },

        [`&.${buttonClasses.disabled}`]: {
          [`.${circularProgressClasses.root}`]: {
            color: palette._grey._400,
          },
        },

        [`&.${classes.cautious}, &.${classes.primary}`]: {
          [`.${circularProgressClasses.root}`]: {
            color: palette.order,
          },
        },

        [`&.${classes.compact}`]: {
          [`.${circularProgressClasses.root}`]: {
            width: '12px !important',
            height: '12px !important',
          },
        },
      },

      [`&.${classes.super}`]: {
        minWidth: '220px',
      },

      [`&.${classes.large}`]: {
        minWidth: '160px',
      },

      [`&.${classes.regular}`]: {
        minWidth: '100px',
      },

      [`&.${classes.compact}`]: {
        width: 'max-content',
        minWidth: 'unset',
        height: '20px',
        padding: `0px ${spacing(3)} !important`,
        borderRadius: '10px',

        fontSize: '12px',
        lineHeight: '16px',
      },

      [`&.${classes.link}`]: {
        width: 'max-content',
        minWidth: 'unset',
        height: '24px',
        padding: `0px ${spacing(3)} !important`,
      },

      [`&.${classes.cautious}`]: {
        [`&.${classes.loading}`]: {
          backgroundColor: palette._grey._700,
        },
      },
    },
  } satisfies SxProps;
});

Button.displayName = 'Button';

export { Button, classes as buttonClasses };
export type ButtonProps = Props;
