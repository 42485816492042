import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import * as React from 'react'

import {MessageSection} from '~/components/sections/message-section'

export default function NotFoundPage() {
  return (
    <main>
      <MessageSection
        title="Page not found"
        subtitle="Error code: 404"
        message="We couldn’t find the page you’re looking for."
      >
        <Typography variant="body2" component="p">
          Visit{' '}
          <Link
            href="https://dash.ansarada.com"
            underline="hover"
            fontWeight="bold"
          >
            home
          </Link>{' '}
          or{' '}
          <Link
            href="https://www.ansarada.com/contact-support"
            underline="hover"
            fontWeight="bold"
          >
            contact us
          </Link>{' '}
          us if you’re having trouble with something.
        </Typography>
      </MessageSection>
    </main>
  )
}
