import type {Analytics} from '@segment/analytics-next'

import Segment from '@ansarada/analytics/lib/segment'

type Callback = (s: Segment) => void
type segmentFunction = (cb: Callback) => void
type SegmentBootstrap = {segment: Segment; setup: Promise<Analytics>}

let singleInstance: SegmentBootstrap
export const setup = async (segmentApiKey: string) => {
  const segment = new Segment()
  singleInstance = {
    segment,
    setup: segment.setup({writeKey: segmentApiKey}),
  }
}

export const waitForSetup = async (callback: () => void) => {
  if (singleInstance) {
    await singleInstance.setup
    window.segment = singleInstance.segment
    callback()
  }
}

export const segment: segmentFunction = cb => {
  const segmentAnalytics: Segment = window.segment

  if (!segmentAnalytics) {
    return
  }

  cb(segmentAnalytics)
}
