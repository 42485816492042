import * as React from 'react'

import {buildValidationSchema} from '~/config/validationSchema'
import type {RequiredProfileFields} from '~/types'
import {isFunction, runIfFn} from '~/utils/misc'
import {mapRequiredFieldsToKeys} from '~/utils/profile'

import {DynamicFormProvider} from './dynamic-form-context'
import type {DynamicFormConfig, DynamicFormValues} from './types'

function useDynamicForm<
  T extends DynamicFormValues,
  P extends RequiredProfileFields,
>(props: DynamicFormConfig<T, P>) {
  const {initialValues, fields, enrich, forceDisplayFields} = props

  const requiredFields = React.useMemo(() => runIfFn(fields), [fields])
  const requiredKeys = React.useMemo(
    () => mapRequiredFieldsToKeys(requiredFields),
    [requiredFields],
  )

  const schema = React.useMemo(
    () => buildValidationSchema(requiredKeys),
    [requiredKeys],
  )

  const values = React.useMemo(
    () =>
      runIfFn<T | undefined, any>(enrich, initialValues, requiredKeys) ??
      initialValues,
    [enrich, initialValues, requiredKeys],
  )

  const ctx = {
    initialValues,
    values,
    fields: requiredFields,
    validationSchema: schema,
    forceDisplayFields,
  }

  return ctx
}

export function DynamicForm<
  T extends DynamicFormValues,
  P extends RequiredProfileFields,
>(props: DynamicFormConfig<T, P>) {
  const dynamicFormBag = useDynamicForm(props)

  return (
    <DynamicFormProvider value={dynamicFormBag}>
      {isFunction(props.children)
        ? props.children(dynamicFormBag)
        : props.children}
    </DynamicFormProvider>
  )
}
