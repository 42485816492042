import { PasswordPolicy, charsets } from 'password-sheriff';

const { lowerCase, upperCase, numbers, specialCharacters } = charsets;

const DEFAULT_MIN_LENGTH = 8;

// Password policies presets used by Auth0.
export const passwordPolices = {
  none: {
    length: { minLength: 1 },
  },
  low: {
    length: { minLength: DEFAULT_MIN_LENGTH },
  },
  fair: {
    length: { minLength: DEFAULT_MIN_LENGTH },
    contains: {
      expressions: [lowerCase, upperCase, numbers],
    },
  },
  good: {
    length: { minLength: DEFAULT_MIN_LENGTH },
    containsAtLeast: {
      atLeast: 3,
      expressions: [lowerCase, upperCase, numbers, specialCharacters],
    },
  },
  excellent: {
    length: { minLength: DEFAULT_MIN_LENGTH },
    containsAtLeast: {
      atLeast: 3,
      expressions: [lowerCase, upperCase, numbers, specialCharacters],
    },
    identicalChars: { max: 2 },
  },
};

type PasswordPolicyLevel = keyof typeof passwordPolices;

export { PasswordPolicy, type PasswordPolicyLevel };
