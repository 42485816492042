/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChevronRightRounded, CloseRounded } from '@mui/icons-material';
import {
  Box,
  Chip,
  ChipProps,
  SxProps,
  Typography,
  chipClasses,
  styled,
  svgIconClasses,
} from '@mui/material';
import cls from 'classnames';
import { isEmpty, isNil, merge } from 'lodash';
import { ElementType, ReactElement, SyntheticEvent, cloneElement, forwardRef } from 'react';

import { useInputValue } from '../../hooks/useInputValue';
import { AnsaradaColor, hexToColorLevels, withAnsaradaColor } from '../../utils/color';
import { ConditionalCompound, MergeObject } from '../../utils/conditionalCompound';
import { UtilityProps } from '../../utils/prop';
import { Number, numberClasses } from './internal/Number';
import { LozengeVariant } from './types';

type VariantProps =
  | {
      /** The variant of the component */
      variant?: LozengeVariant.SIMPLE | `${LozengeVariant.SIMPLE}`;
    }
  | {
      /** The variant of the component */
      variant: LozengeVariant.INTERACTIVE | `${LozengeVariant.INTERACTIVE}`;

      /** The color of component */
      color?: AnsaradaColor;

      /** The link of the component to point to */
      link?: string;

      /** The component to render the link */
      linkComponent?: ElementType;

      /** The icon of the component */
      icon?: ReactElement;

      /** The tally of the component */
      number?: number | string;

      /** The value of the component */
      value?: boolean;

      /** The default value of the component */
      defaultValue?: boolean;

      /** The function runs when value of the component changes */
      onChange?: (v: boolean) => void;

      /** The function runs when the component gets clicked */
      onClick?: (e: SyntheticEvent) => void;

      /** Disable the component */
      disabled?: boolean;
    }
  | {
      /** The variant of the component */
      variant: LozengeVariant.INPUT_SELECT | `${LozengeVariant.INPUT_SELECT}`;

      /** The icon of the component */
      icon?: ReactElement;

      /** The tally of the component */
      number?: number | string;

      /** The function runs when the dropdown of the component opens */
      onOpen: (e: any) => void;

      /** Disable the component */
      disabled?: boolean;
    }
  | {
      /** The variant of the component */
      variant: LozengeVariant.INPUT_SELECTION | `${LozengeVariant.INPUT_SELECTION}`;

      /** The icon of the component */
      icon?: ReactElement;

      /** The tally of the component */
      number?: number | string;

      /** If defined, a cross icon will be shown when hovering, triggering this callback when clicking */
      onDelete?: ChipProps['onDelete'];

      /** Disable the component */
      disabled?: boolean;
    };

type MergedVariants = MergeObject<VariantProps>;

type CommonProps = {
  /** The label of the component */
  label: string;

  /** The max width of the component, `label` will be truncated in order for it to match this property */
  maxWidth?: number | string;

  /** By default, Lozenge will forcefully use variant `500` from Ansarada color palette, `true` to disable that behavior  */
  disableColorForcing?: boolean;
} & UtilityProps;

type ExternalProps = CommonProps &
  ConditionalCompound<VariantProps> &
  Omit<ChipProps, keyof CommonProps | keyof MergedVariants>;

type InternalProps = CommonProps &
  MergedVariants &
  Omit<ChipProps, keyof CommonProps | keyof MergedVariants>;

const classes = {
  SIMPLE: 'Lozenge--simple',
  INTERACTIVE: 'Lozenge--interactive',
  INPUT_SELECT: 'Lozenge--input-select',
  INPUT_SELECTION: 'Lozenge--input-selection',

  labelElement: 'Lozenge__label',
  iconWrapper: 'Lozenge__icon-wrapper',
  dropdownChevronWrapper: 'Lozenge__dropdown-wrapper',
  dropdownChevron: 'Lozenge__dropdown-chevron',
  selected: 'Lozenge__selected',

  iconVariant: 'Lozenge__icon-variant',
  tallyVariant: 'Lozenge__number-variant',
  iconAndTallyVariant: 'Lozenge__icon-and-number-variant',
  iconAndTallyOnly: 'Lozenge--icon-and-number-only',
  iconOnly: 'Lozenge__icon-only',
  tallyOnly: 'Lozenge__number-only',

  iconOnInputSelect: 'Lozenge__icon-on-select',
  iconOnInputSelection: 'Lozenge__icon-on-selection',
};

/** This component inherits [MUI Chip's API](https://mui.com/material-ui/api/chip/)\
 * See the [API documented on Storybook](https://ansarada-design-system.vercel.app/?path=/docs/elements-lozenge--documentation)
 * ---
 * @componentName Lozenge
 * @abc Lozenge
 */
const BaseLozenge = styled(
  forwardRef<HTMLDivElement, ExternalProps>((props: ExternalProps, ref) => {
    const {
      color,
      label,
      variant = LozengeVariant.SIMPLE,
      onClick,
      value,
      onChange,
      number,
      maxWidth: _maxWidth,
      defaultValue,
      link,
      linkComponent,
      icon,
      onOpen,
      className,
      disableColorForcing: _disableColorForcing,
      ...rest
    } = props as InternalProps;

    const isShowingIcon = !isNil(icon);
    const isShowingLabel = !isEmpty(label);
    const isShowingNumber = !isNil(number);

    const variants = {
      isIconVariant: isShowingIcon && isShowingLabel && !isShowingNumber,
      isTallyVariant: !isShowingIcon && isShowingLabel && isShowingNumber,
      isIconAndTallyVariant: isShowingIcon && isShowingLabel && isShowingNumber,
      isIconAndTallyOnly: isShowingIcon && !isShowingLabel && isShowingNumber,
      isIconOnly: isShowingIcon && !isShowingLabel && !isShowingNumber,
      isTallyOnly: !isShowingIcon && !isShowingLabel && isShowingNumber,
      isIconOnSelect: isShowingIcon && variant === LozengeVariant.INPUT_SELECT,
      isIconOnSelection: isShowingIcon && variant === LozengeVariant.INPUT_SELECTION,
    };

    const {
      isIconVariant,
      isTallyVariant,
      isIconAndTallyVariant,
      isIconAndTallyOnly,
      isIconOnly,
      isTallyOnly,
      isIconOnSelect,
      isIconOnSelection,
    } = variants;

    const { handleInternalValueChange, internalValue } = useInputValue({
      value,
      defaultValue,
      onChange,
    });

    return (
      <Chip
        {...merge(
          {
            size: 'small',
            clickable: variant !== LozengeVariant.SIMPLE,
            onClick: (e: any) => {
              onClick?.(e);
              onOpen?.(e);
              handleInternalValueChange(!internalValue);
            },
            deleteIcon: (
              <Box>
                <CloseRounded />
              </Box>
            ),
            label: (
              <>
                {(variant === LozengeVariant.INTERACTIVE ||
                  variant === LozengeVariant.INPUT_SELECT ||
                  variant === LozengeVariant.INPUT_SELECTION) &&
                  icon && (
                    <Box
                      className={cls(
                        classes.iconWrapper,
                        isIconAndTallyOnly && classes.iconAndTallyOnly,
                        isIconOnly && classes.iconOnly,
                        isIconOnSelect && classes.iconOnInputSelect,
                        isIconOnSelection && classes.iconOnInputSelection,
                      )}
                    >
                      {cloneElement(icon, {
                        fontSize: 'small',
                      })}
                    </Box>
                  )}

                {isShowingLabel && (
                  <Typography
                    className={cls(
                      classes.labelElement,
                      isIconVariant && classes.iconVariant,
                      isTallyVariant && classes.tallyVariant,
                      isIconAndTallyVariant && classes.iconAndTallyVariant,
                    )}
                    variant="caption"
                    noWrap
                  >
                    {label}
                  </Typography>
                )}

                {isShowingNumber && variant === LozengeVariant.INTERACTIVE && (
                  <Number backgroundColor={color} className={cls(isTallyOnly && classes.tallyOnly)}>
                    {number}
                  </Number>
                )}

                {isShowingNumber &&
                  (variant === LozengeVariant.INPUT_SELECT ||
                    variant === LozengeVariant.INPUT_SELECTION) && (
                    <Typography component="span" variant="caption">
                      {number}
                    </Typography>
                  )}

                {variant === LozengeVariant.INPUT_SELECT && (
                  <Box className={`${classes.dropdownChevronWrapper}`}>
                    <ChevronRightRounded
                      className={`${classes.dropdownChevron}`}
                      fontSize="medium"
                    />
                  </Box>
                )}
              </>
            ),
            component: linkComponent,
            href: link,
            to: link,
          } satisfies Partial<ChipProps> & { component?: ElementType; href?: string; to?: string },
          rest,
          {
            className: cls(className, classes[variant], internalValue && classes.selected),
          },
        )}
        ref={ref}
      />
    );
  }),
)(({ theme: { palette, shape, spacing }, ...props }) => {
  const { color } = props as InternalProps;
  const defaultBackgroundColor = 'transparent';
  const colorLevels = hexToColorLevels(color ?? palette._grey._500);
  const adjustedBorderRadius = shape.borderRadius * 1.25;

  return {
    '&': {
      boxShadow: `inset 0px 0px 0px 1px ${color ?? palette._grey._500}`,
      backgroundColor: defaultBackgroundColor,
      textDecoration: 'none !important',
      height: '20px',
      borderRadius: `${adjustedBorderRadius}px`,

      [`& .${classes.iconWrapper}`]: {
        height: '12px',
        fontSize: '12px',
        display: 'flex',
        alignItems: 'center',

        [`.${svgIconClasses.root}`]: {
          color: palette._grey._700,
          transform: 'translateY(0px)',
          fontSize: '12px',
        },
      },

      [`& .${chipClasses.label}`]: {
        display: 'flex',
        alignItems: 'center',
        color: palette._grey._600,
        padding: '0px',
        margin: `0px ${spacing(2)}`,

        [`& .${classes.labelElement}`]: {
          color: palette._grey._700,
          padding: `0px ${spacing(1)}`,
        },

        [`& .${numberClasses.root}`]: {
          color: palette._grey._700,
        },
      },

      [`& .${chipClasses.deleteIcon}`]: {
        color: `${palette._grey._700}`,
        margin: '0px 2px 0px 0px',
        height: spacing(4),
        width: spacing(4),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },

      [`&:hover`]: {
        [`& .${classes.dropdownChevronWrapper}, & .${chipClasses.deleteIcon}`]: {
          transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          backgroundColor: `${palette.order}`,
          borderRadius: `${adjustedBorderRadius}px`,
        },
      },

      [`&.${classes.SIMPLE}`]: {
        [`& .${chipClasses.label}`]: {
          [`& .${classes.labelElement}`]: {
            color: palette._grey._700,
            padding: `0px 2px`,
          },
        },
      },

      [`&.${classes.INTERACTIVE}`]: {
        boxShadow: `inset 0px 0px 0px 1px ${colorLevels?._200 ?? palette._grey._200}`,
        backgroundColor: `${colorLevels?._200 ?? palette._grey._200}`,

        '&:hover': {
          boxShadow: `inset 0px 0px 0px 1px ${colorLevels?._500 ?? palette._grey._500}`,
        },

        [`& .${chipClasses.label}`]: {
          [`&:has(.${classes.iconVariant})`]: {
            margin: '0px 8px 0px 4px',
          },
          [`&:has(.${classes.tallyVariant})`]: {
            margin: '0px 2px 0px 8px',
          },
          [`&:has(.${classes.iconAndTallyVariant})`]: {
            margin: '0px 2px 0px 4px',
          },
          [`&:has(.${classes.iconAndTallyOnly})`]: {
            margin: '0px 2px 0px 4px',
          },
          [`&:has(.${classes.iconOnly})`]: {
            margin: '0px 4px 0px 4px',
          },
          [`&:has(.${classes.tallyOnly})`]: {
            margin: '0px 2px 0px 2px',
          },

          [`& .${classes.iconWrapper}`]: {
            [`& .${svgIconClasses.root}`]: {
              color: palette._grey._700,
            },
          },

          [`& .${classes.labelElement}`]: {
            color: palette.chaos,
          },
          [`& .${classes.tallyVariant}`]: {
            padding: `0px ${spacing(2)} 0px 0px`,
          },
          [`& .${classes.iconAndTallyVariant}`]: {
            padding: `0px ${spacing(2)} 0px ${spacing(1)}`,
          },
          [`& .${classes.iconAndTallyOnly}`]: {
            padding: `0px ${spacing(1)} 0px 0px`,
          },
          [`& .${classes.iconOnly}`]: {
            padding: `0px`,
          },
          [`& .${classes.tallyOnly}`]: {
            padding: `0px ${spacing(1)}`,
          },

          [`& .${numberClasses.root}`]: {
            backgroundColor: `${palette.order}`,
          },
        },

        [`&.Mui-disabled`]: {
          boxShadow: `inset 0px 0px 0px 1px ${colorLevels?._200 ?? palette._grey._200}`,
          backgroundColor: defaultBackgroundColor,
          [`& .${chipClasses.label}`]: {
            [`& .${classes.iconWrapper}`]: {
              [`& .${svgIconClasses.root}`]: {
                color: `${palette._grey._200}`,
              },
            },

            [`& .${classes.labelElement}`]: {
              color: `${palette._grey._200}`,
            },

            [`& .${numberClasses.root}`]: {
              backgroundColor: `${colorLevels?._200 ?? palette._grey._200}`,
              color: `${palette.order}`,
            },
          },

          opacity: 1,
        },
      },

      [`&.${classes.INPUT_SELECT}`]: {
        boxShadow: `inset 0px 0px 0px 1px ${colorLevels?._200 ?? palette._grey._200}`,
        backgroundColor: `${colorLevels?._200 ?? palette._grey._200}`,

        [`&:hover`]: {
          boxShadow: `inset 0px 0px 0px 1px ${colorLevels?._500 ?? palette._grey._500}`,
        },

        [`& .${chipClasses.label}`]: {
          margin: `0px 2px 0px ${spacing(2)}`,
          [`&:has(.${classes.iconOnInputSelect})`]: {
            margin: `0px 2px 0px ${spacing(1)}`,
          },

          [`& .${classes.iconOnInputSelect}`]: {
            padding: `0px ${spacing(1)} 0px 0px}`,
          },

          [`& .${classes.labelElement}`]: {
            color: palette.chaos,
            padding: `0px ${spacing(2)} 0px 0px`,
          },

          [`& .${classes.dropdownChevronWrapper}`]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: spacing(4),
            width: spacing(4),

            [`& .${classes.dropdownChevron}`]: {
              rotate: '90deg',
              height: spacing(4),
              width: spacing(4),
            },
          },
        },

        [`&.Mui-disabled`]: {
          boxShadow: `inset 0px 0px 0px 1px ${colorLevels?._200 ?? palette._grey._200}`,
          backgroundColor: defaultBackgroundColor,
          [`& .${chipClasses.label}`]: {
            [`& .${classes.iconWrapper}`]: {
              [`& .${svgIconClasses.root}`]: {
                color: `${palette._grey._200}`,
              },
            },

            [`& .${classes.labelElement}`]: {
              color: `${palette._grey._200}`,
            },

            [`& .${numberClasses.root}`]: {
              backgroundColor: `${palette._grey._200}`,
              color: `${palette.order}`,
            },
          },

          [`& .${classes.dropdownChevronWrapper}`]: {
            [`& .${svgIconClasses.root}`]: {
              color: `${palette._grey._200}`,
            },
          },

          opacity: 1,
        },
      },

      [`&.${classes.INPUT_SELECTION}`]: {
        boxShadow: `inset 0px 0px 0px 1px ${colorLevels?._200 ?? palette._grey._200}`,
        backgroundColor: `${colorLevels?._200 ?? palette._grey._200}`,

        [`& .${chipClasses.label}`]: {
          margin: `0px 0px 0px ${spacing(2)}`,

          [`&:has(.${classes.iconOnInputSelection})`]: {
            margin: `0px 0px 0px ${spacing(1)}`,
          },

          [`& .${classes.iconOnInputSelection}`]: {
            padding: `0px ${spacing(1)} 0px 0px}`,
          },

          [`& .${classes.labelElement}`]: {
            color: palette.chaos,
            padding: `0px ${spacing(2)} 0px 0px`,
          },
        },

        [`.${svgIconClasses.root}`]: {
          fontSize: '14px',
        },

        [`&.Mui-disabled`]: {
          boxShadow: `inset 0px 0px 0px 1px ${colorLevels?._200 ?? palette._grey._200}`,
          backgroundColor: defaultBackgroundColor,
          [`& .${chipClasses.label}`]: {
            [`& .${classes.iconWrapper}`]: {
              [`& .${svgIconClasses.root}`]: {
                color: `${palette._grey._200}`,
              },
            },

            [`& .${classes.labelElement}`]: {
              color: `${palette._grey._200}`,
            },

            [`& .${numberClasses.root}`]: {
              backgroundColor: `${palette._grey._200}`,
              color: `${palette.order}`,
            },
          },

          [`& .${chipClasses.deleteIcon}`]: {
            [`& .${svgIconClasses.root}`]: {
              color: `${palette._grey._200}`,
            },
          },

          opacity: 1,
        },
      },

      [`&.${classes.selected}:not(.${classes.INPUT_SELECT})`]: {
        boxShadow: `inset 0px 0px 0px 1px ${colorLevels?._600 ?? palette._grey._600}`,
        [`&:hover`]: {
          boxShadow: `inset 0px 0px 0px 1px ${colorLevels?._600 ?? palette._grey._600}`,
        },
      },
    } satisfies SxProps,
  };
});

const Lozenge = withAnsaradaColor(BaseLozenge, {
  // @ts-expect-error color is a legit field
  colorFieldKeys: ['color'],
  forceColorVariant: '_500',
});

export { Lozenge, LozengeVariant, classes as lozengeClasses };
