import {
  type UseMutationOptions,
  type UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query'
import * as React from 'react'

import {useClient} from '~/context/auth-context'
import {queryClient} from '~/lib/react-query'
import {toast} from '~/lib/toast'
import type {DetailedProfileInfo, UpdateProfileDto} from '~/types'

const useProfileInfo = (options?: UseQueryOptions<DetailedProfileInfo>) => {
  const client = useClient<DetailedProfileInfo & {signUpCompleted: boolean}>({
    protectedClient: true,
  })

  const {data: profileInfo} = useQuery({
    queryKey: ['profile-info'],
    queryFn: () =>
      client('profiles/v1/me').then(data => ({
        ...data,
        hasExistingPassword: data.signUpCompleted,
      })),
    ...options,
  })

  return profileInfo
}

const useUpdateProfileInfo = <T = unknown>(
  options?: UseMutationOptions<T, unknown, UpdateProfileDto>,
) => {
  const client = useClient<T>({protectedClient: true})

  return useMutation(
    newProfileInfo =>
      client(`profiles/v1/me`, {
        method: 'PATCH',
        data: newProfileInfo,
      }),
    {
      onMutate: async (newItem: UpdateProfileDto) => {
        await queryClient.cancelQueries(['profile-info'])

        const previousProfile = queryClient.getQueryData<DetailedProfileInfo>([
          'profile-info',
        ])
        const newProfile = {...previousProfile, ...newItem}

        queryClient.setQueryData(['profile-info'], newProfile)
      },
      onSuccess: () => toast.success('Saved successfully'),
      ...options,
    },
  )
}

const useResetPassword = (options?: UseMutationOptions<void>) => {
  const client = useClient<void>({protectedClient: true})
  const [resetPasswordSent, setResetPasswordSent] = React.useState(false)

  const resetPassword = useMutation(
    () =>
      client('profiles/v1/me/password/reset-link/send', {
        method: 'POST',
      }),
    {
      onSuccess: () => setResetPasswordSent(true),
      onSettled: () => setResetPasswordSent(false),
      ...options,
    },
  )

  return {
    resetPassword,
    resetPasswordSent,
  }
}

export {useProfileInfo, useResetPassword, useUpdateProfileInfo}
