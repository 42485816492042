import * as React from 'react'

import {ApiError} from '~/lib/api-error'
import type {NonNullProperties} from '~/types'

function getErrorMessage(error: unknown) {
  if (typeof error === 'string') return error
  if (error instanceof Error) return error.message
  if (error instanceof ApiError) return error.message
  return 'Unknown Error'
}

function getNonNull<Type extends Record<string, null | undefined | unknown>>(
  obj: Type,
): NonNullProperties<Type> {
  for (const [key, val] of Object.entries(obj)) {
    assertNonNull(val, `The value of ${key} is null but it should not be.`)
  }
  return obj as NonNullProperties<Type>
}

function assertNonNull<PossibleNullType>(
  possibleNull: PossibleNullType,
  errorMessage: string,
): asserts possibleNull is Exclude<PossibleNullType, null | undefined> {
  if (possibleNull == null) throw new Error(errorMessage)
}

function getRequiredEnvVarFromObj(
  obj: Record<string, string | undefined>,
  key: string,
  devValue: string = `${key}-dev-value`,
) {
  let value = devValue
  const envVal = obj[key]
  if (envVal) {
    value = envVal
  } else if (obj.NODE_ENV === 'production') {
    throw new Error(`${key} is a required env variable`)
  }
  return value
}

function getRequiredServerEnvVar(key: string, devValue?: string) {
  return getRequiredEnvVarFromObj(process.env, key, devValue)
}

function debounce<Callback extends (...args: Parameters<Callback>) => void>(
  fn: Callback,
  delay: number,
) {
  let timer: ReturnType<typeof setTimeout> | null = null
  return (...args: Parameters<Callback>) => {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}

function useDebounce<
  Callback extends (...args: Parameters<Callback>) => ReturnType<Callback>,
>(callback: Callback, delay: number) {
  const callbackRef = React.useRef(callback)
  React.useEffect(() => {
    callbackRef.current = callback
  })
  return React.useMemo(
    () =>
      debounce(
        (...args: Parameters<Callback>) => callbackRef.current(...args),
        delay,
      ),
    [delay],
  )
}

const isFunction = <T extends Function = Function>(value: any): value is T =>
  typeof value === 'function'

function runIfFn<T, U>(
  valueOrFn: T | ((...fnArgs: U[]) => T),
  ...args: U[]
): T {
  return isFunction(valueOrFn) ? valueOrFn(...args) : valueOrFn
}

function getAssetCallbackUrl({
  assetType,
  queryKey,
}: {
  assetType: 'dataroom'
  queryKey: string
}) {
  const searchParams = new URLSearchParams(window.location.search)
  const queryParam = searchParams.get(queryKey) ?? ''
  let callbackUrl = ''

  if (assetType === 'dataroom') {
    const [key, value] = queryParam.split('assetCallbackUrl=')
    callbackUrl = !key && value ? value : ''
  }

  return decodeURIComponent(callbackUrl)
}

export {
  assertNonNull,
  debounce,
  getAssetCallbackUrl,
  getErrorMessage,
  getNonNull,
  getRequiredServerEnvVar,
  isFunction,
  runIfFn,
  useDebounce,
}
