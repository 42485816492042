import { Box, Typography } from '@mui/material';

import { LogomarkAnsarada as AnsaradaIcon } from '@ansarada/icons/lib/logomark-ansarada';

import { MenuItemGroup } from '../menu';
import { HeaderMenu } from './header-menu';

interface ProductMenuProps {
  name: string;
  menu?: MenuItemGroup[] | null;
  onClickLogo?: () => void;
}

const ProductMenu = ({ name, menu, onClickLogo }: ProductMenuProps) => {
  let render = (
    <HeaderMenu
      id="product-menu"
      startIcon={<AnsaradaIcon size="XLarge" color="white" />}
      menu={menu}
      label={
        <Typography
          variant="h6"
          fontWeight={700}
          ml={1}
          sx={{ display: { xs: 'none', md: 'inherit' } }}
        >
          {name}
        </Typography>
      }
    />
  );

  if (onClickLogo) {
    render = (
      <Box display="flex">
        <Box onClick={onClickLogo}>
          <HeaderMenu
            id="product-logo"
            startIcon={<AnsaradaIcon size="XLarge" color="white" />}
            label={
              <Typography
                variant="h6"
                fontWeight={700}
                ml={1}
                sx={{ display: { xs: 'none', md: 'inherit' } }}
              >
                {name}
              </Typography>
            }
          />
        </Box>
        <HeaderMenu
          id="product-switcher"
          menu={menu}
          label=""
          ButtonProps={{ sx: { minWidth: 'unset' } }}
        />
      </Box>
    );
  }

  return render;
};

export { ProductMenu };
