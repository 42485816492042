import * as uiColorsV1 from '@ansarada/colors/lib/design-system-colors/v1';

const SelectIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
      <path
        d="M5.66 12L12.07 0H9.79999L4.41998 10.09L2.56998 7.81H0L3.39999 12H5.66Z"
        fill={uiColorsV1.grey._600}
      />
    </svg>
  );
};

export { SelectIcon };
