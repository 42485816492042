import { CloseOutlined } from '@mui/icons-material';
import { Box, BoxProps, useTheme } from '@mui/material';

type ClearIconProps = BoxProps;

export default function ClearIcon(props: ClearIconProps) {
  const { palette } = useTheme();

  return (
    <Box display="flex" alignItems="center" justifyContent="center" {...props}>
      <CloseOutlined
        sx={{
          width: '16px',
          height: '16px',
          p: '2px',
          color: palette._grey._500,
          cursor: 'pointer',
          transition: 'all 0.2s ease',
          borderRadius: '50%',
          ':hover': { color: 'white', bgcolor: palette._grey._500 },
          ':active': { color: 'white', bgcolor: palette._grey._600 },
        }}
      />
    </Box>
  );
}
