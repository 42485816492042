import {useAuth0} from '@auth0/auth0-react'
import LoadingButton from '@mui/lab/LoadingButton'
import Link from '@mui/material/Link'
import React from 'react'
import {useSearchParams} from 'react-router-dom'

import {withTheme} from '~/components/hoc/withTheme'
import {ResetMFALayout} from '~/components/layout/reset-mfa-layout'
import {Progress} from '~/components/progress'
import {MessageSection} from '~/components/sections/message-section'
import config from '~/config'
import {useResetMFA} from '~/hook/use-reset-mfa'

export const ResetMFAComplete = withTheme('ESGLight', () => {
  const [searchParams] = useSearchParams()
  const {logout} = useAuth0()
  const {isLoading, isSuccess} = useResetMFA({
    variables: {token: searchParams.get('token') ?? ''},
  })

  const goToLogin = () => {
    logout({
      returnTo: config.auth.logoutRedirectUri,
    })
  }
  const ContactSupportLink = (
    <Link href="https://www.ansarada.com/contact-support">contact us</Link>
  )

  return isLoading ? (
    <MessageSection title={<Progress />} />
  ) : isSuccess ? (
    <ResetMFALayout
      title="You're good to go!"
      content={
        <span>
          Your access has been reset and you will need to log back in.
        </span>
      }
      renderAction={() => (
        <LoadingButton
          variant="contained"
          disabled={isLoading}
          loading={isLoading}
          onClick={goToLogin}
          sx={{
            borderRadius: '4px',
          }}
        >
          Login
        </LoadingButton>
      )}
    />
  ) : (
    <MessageSection title="Reset MFA Error" subtitle={`Error code: Invalid`}>
      <p data-test-id="mfa-reset-error">
        {'This link is invalid or expired.'}
        <br />
        Please {ContactSupportLink} for more information!
      </p>
    </MessageSection>
  )
})
