import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import * as React from 'react'

import appConfig from '~/config'
import type {ErrorCode} from '~/types'

import {MessageSection} from './message-section'

type InviteErrorSectionProps = {
  errorCode?: ErrorCode
}

function InviteErrorSection({errorCode = 'NotFound'}: InviteErrorSectionProps) {
  const ContactSupportLink = (
    <Link href="https://www.ansarada.com/contact-support">contact us</Link>
  )

  const LoginLink = <Link href={appConfig.platformHomeUrl}>login</Link>

  const getInviteErrorMessage = () => {
    switch (errorCode) {
      case 'Invalid':
        return (
          <Typography variant="body2" data-test-id="invite-error">
            Invitation link is invalid.
            <br />
            Please contact your administrators for more information!
          </Typography>
        )
      case 'Expired':
        return (
          <Typography variant="body2" data-test-id="invite-error">
            <br />
            Please ask your administrators to send invitation again!
          </Typography>
        )
      case 'Accepted':
        return (
          <Typography variant="body2" data-test-id="invite-error">
            Invitation has been verified. You are able to {LoginLink} now!
          </Typography>
        )
      case 'NotFound':
        return (
          <Typography variant="body2" data-test-id="invite-error">
            Invitation link is not found.
            <br />
            Please contact your administrators for more information!
          </Typography>
        )
      default:
        return (
          <Typography variant="body2" data-test-id="invite-error">
            {"We weren't able to load information about this invite."}
            <br />
            Please {ContactSupportLink} for more information!
          </Typography>
        )
    }
  }

  return (
    <MessageSection
      title="Invitation Error"
      subtitle={`Error code: ${errorCode}`}
    >
      {getInviteErrorMessage()}
    </MessageSection>
  )
}

export {InviteErrorSection}
