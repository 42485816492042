import { accordionSummaryClasses, formLabelClasses } from '@mui/material';
import { PaletteOptions, Theme, ThemeOptions, createTheme } from '@mui/material/styles';

import * as colors from '@ansarada/colors/lib/common';
import * as projectCitrus from '@ansarada/colors/lib/project-citrus';

import {
  ANSARADA_REGULAR_FONTS,
  ANSARADA_VARIABLE_FONTS,
  generateAnsaradaFavoritFontFace,
  supportVariableFont,
} from '../fonts';
import { ThemeMode } from '../types';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    ghost: true;
    link: true;
    destructive: true;
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    ace: true;
  }
}

declare module '@mui/material/styles' {
  interface ThemeOptions {
    name: string;
  }
}

function createPalette(mode: ThemeMode): PaletteOptions {
  return {
    primary: {
      main: colors.black,
      dark: colors.black,
      light: '#656662',
    },
    secondary: {
      main: projectCitrus.primary[500],
      light: '#DEFD61',
      dark: '#93B703',
    },
    error: {
      main: '#FB0069',
      light: '#FC257F',
      dark: '#E80162',
    },
    warning: projectCitrus.warning,
    success: {
      main: '#00FB69',
      light: '#61FDA2',
      dark: '#02B74F',
    },
    info: {
      main: '#00C9FB',
      light: '#61DEFD',
      dark: '#0293B8',
    },
    text: {
      primary: colors.black,
      secondary: colors.black,
      disabled: colors.black,
      // hint: '#07070C',
    },
    background: {
      default: colors.neutral50,
    },
    // primary: {
    //   main: colors.brand500,
    //   dark: colors.brand550,
    // },
    // secondary: {
    //   main: colors.brand600,
    // },
    // error: {
    //   main: colors.functionalRed600,
    //   contrastText: colors.white,
    // },
    // warning: {
    //   main: colors.citrus,
    //   light: colors.mandarine,
    //   contrastText: colors.textBase,
    // },
    // info: {
    //   main: colors.dawn,
    //   light: colors.berry,
    //   contrastText: colors.textBase,
    // },
    // success: {
    //   main: colors.functionalGreen500,
    //   contrastText: colors.white,
    // },
    mode,
    common: {
      black: projectCitrus.common.black,
      white: colors.white,
    },
    // text: {
    //   primary: mode === 'light' ? colors.textBase : colors.textWhite,
    //   secondary: mode === 'light' ? colors.textLight : colors.textWhite,
    // },
    grey: {
      50: colors.neutral50,
      100: colors.neutral100,
      200: colors.neutral200,
      300: colors.neutral300,
      400: colors.neutral400,
      500: colors.neutral500,
      600: colors.neutral600,
    },
    divider: colors.neutral300,
  };
}

type Args = {
  name: string;
  mode: ThemeMode;
};

export function createWebsiteTheme({ name, mode }: Args): Theme {
  const themeOptions: ThemeOptions = {
    name,
    palette: createPalette(mode),
    spacing: [2, 4, 8, 12, 16, 24, 32, 64, 80, 128, 140],
    shape: {
      borderRadius: 0,
    },
    shadows: [
      'none',
      '0px 1px 2px rgba(0, 0, 0, 0.2), 0px 1px 3px rgba(0, 0, 0, 0.1)',
      '0px 3px 6px rgba(0, 0, 0, 0.23), 0px 3px 6px rgba(0, 0, 0, 0.16)',
      '0px 6px 6px rgba(0, 0, 0, 0.26), 0px 10px 20px rgba(0, 0, 0, 0.19)',
      '0px 10px 10px rgba(0, 0, 0, 0.26), 0px 14px 28px rgba(0, 0, 0, 0.25)',
      '0px 15px 12px rgba(0, 0, 0, 0.22), 0px 19px 38px rgba(0, 0, 0, 0.3)',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
    ],
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          ${generateAnsaradaFavoritFontFace('variable')}
          ${generateAnsaradaFavoritFontFace('regular')}
          ${generateAnsaradaFavoritFontFace('bold')}

          @supports (font-variation-settings: normal) {
            * {
              font-family: ${ANSARADA_VARIABLE_FONTS.join(',')} !important;
            }
          }
        `,
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            // border: 'none !important',
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            [`&.${formLabelClasses.focused}`]: {
              color: `${projectCitrus.common.black}`,
            },
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            [`&.${accordionSummaryClasses.root}`]: {
              boxShadow: 'none',
            },
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            '&.MuiPaper': {
              boxShadow: 'none',
            },
            boxShadow: 'none',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&.Mui-focused > fieldset.MuiOutlinedInput-notchedOutline': {
              borderColor: `${projectCitrus.common.black}`,
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            color: projectCitrus.common.black,
            backgroundColor: projectCitrus.primary[500],
            ...(ownerState.underline === 'hover' && {
              '&:hover': {
                textDecoration: 'underline !important',
              },
            }),
            ...(ownerState.underline === 'always' && {
              textDecoration: 'underline !important',
            }),
          }),
        },
        variants: [
          {
            props: { variant: 'button' },
            style: {
              backgroundColor: 'transparent',
              textDecorationColor: projectCitrus.common.black,
              ':hover': {
                backgroundColor: projectCitrus.primary[500],
              },
            },
          },
          {
            props: { variant: 'ace' },
            style: {
              backgroundColor: 'transparent',
              textDecorationColor: projectCitrus.common.black,
            },
          },
        ],
      },
      MuiButton: {
        variants: [
          {
            props: { variant: 'ghost' },
            style: {
              color: colors.black,
              backgroundColor: 'transparent',
              textDecoration: 'underline',

              '&:hover': {
                backgroundColor: 'transparent',
                textDecoration: 'none',
                boxShadow: `inset 0 0 0 1px ${colors.black}`,
              },

              '&:active': {
                backgroundColor: 'transparent',
                textDecoration: 'underline',
                boxShadow: `inset 0 0 0 2px ${colors.black}`,
              },

              '&:disabled': {
                backgroundColor: 'transparent',
                color: '#C4C2C2',
              },
            },
          },
          {
            props: { variant: 'link' },
            style: {
              color: colors.black,
              backgroundColor: 'transparent',
              textDecoration: 'underline',

              '&:hover': {
                textDecoration: 'none',
                backgroundColor: 'transparent',
              },

              '&:active': {
                textDecoration: 'underline',
              },
            },
          },
          {
            props: { variant: 'destructive' },
            style: {
              color: colors.black,
              backgroundColor: projectCitrus.warning.main,
              textDecoration: 'none',

              '&:hover': {
                backgroundColor: projectCitrus.warning.main,
                boxShadow: `inset 0 0 0 1px ${colors.black}`,
                textDecoration: 'none',
              },

              '&:active': {
                boxShadow: `inset 0 0 0 2px ${colors.black}`,
              },

              '&:disabled': {
                backgroundColor: '#C4C2C2',
              },
            },
          },
        ],
        styleOverrides: {
          outlined: {
            borderRadius: '12px',
            color: projectCitrus.common.black,
            borderColor: 'transparent',
          },
          contained: {
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
          },
          sizeMedium: {
            minWidth: '120px',
            height: '40px',
            borderRadius: '12px',
            fontSize: '16px',
            lineHeight: '20px',
            paddingTop: '11px',
            paddingBottom: '9px',
            fontWeight: '700',
          },
          containedPrimary: {
            '&:hover': {
              boxShadow: `inset 0px 0px 0px 1px ${projectCitrus.primary[500]}`,
            },
            '&:active': {
              boxShadow: `inset 0px 0px 0px 2px ${projectCitrus.primary[500]}`,
            },
            '&:disabled': {
              backgroundColor: '#C4C2C2',
              color: colors.white,
            },
          },
          outlinedSecondary: {
            backgroundColor: colors.white,
            boxShadow: `inset 0 0 0 2px ${colors.black}`,

            '&:hover': {
              boxShadow: `inset 0 0 0 1px ${colors.black}`,
              backgroundColor: colors.white,
              borderColor: colors.black,
            },
            '&:active': {
              boxShadow: `inset 0 0 0 2px ${colors.black}`,
              backgroundColor: colors.white,
            },
            '&:disabled': {
              color: '#C4C2C2',
              boxShadow: 'inset 0 0 0 2px #c4c2c2',
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            border: `1px solid ${projectCitrus.common.black}`,
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            paddingTop: '0px',
            paddingBottom: '0px',
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          title: {
            fontSize: 18,
            fontWeight: 700,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            // backgroundImage: 'radial-gradient(#f1f1f1, #fff)',
            borderRadius: 0,
            boxShadow: 'none',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
          },
          elevation0: {
            '&.MuiCard-root': {
              backgroundImage: 'none',
            },
          },
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            color: projectCitrus.common.white,
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: projectCitrus.common.black,
            '&.Mui-checked': {
              color: projectCitrus.common.black,
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: 'auto',
            // height: '32px',
            // minHeight: '32px',
          },
          flexContainer: {
            // height: '32px',
          },
          indicator: {
            backgroundColor: '#fff',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            minHeight: 40,
            paddingTop: 8,
            paddingBottom: 8,
            backgroundColor: '#D8D8D8',
            // backgroundColor: '#07070C',
            // color: '#fff',
            '&.Mui-selected': {
              backgroundColor: '#fff',
              color: '#07070C',
            },
          },
        },
      },
    },
    typography: {
      fontFamily: ANSARADA_REGULAR_FONTS.join(','),
      h1: {
        fontSize: '80px',
        lineHeight: '72px',
        letterSpacing: '-4%',
        fontWeight: 400,
        ...supportVariableFont('regular', { fontVariationSettings: "'wdth' 400" }),
      },
      h2: {
        fontSize: '40px',
        lineHeight: '44px',
        letterSpacing: '-4%',
        fontWeight: 700,
        ...supportVariableFont('bold', { fontVariationSettings: "'wdth' 700" }),
      },
      h3: {
        fontSize: '40px',
        lineHeight: '44px',
        letterSpacing: '-3%',
        fontWeight: 350,
        ...supportVariableFont('regular', { fontVariationSettings: "'wdth' 350" }),
      },
      h4: {
        fontSize: '32px',
        lineHeight: '36px',
        letterSpacing: '-4%',
        fontWeight: 700,
        ...supportVariableFont('bold', { fontVariationSettings: "'wdth' 700" }),
      },
      h5: {
        fontSize: '32px',
        lineHeight: '36px',
        letterSpacing: '-4%',
        fontWeight: 350,
        ...supportVariableFont('regular', { fontVariationSettings: "'wdth' 350" }),
      },
      h6: {
        fontSize: '24px',
        lineHeight: '28px',
        letterSpacing: '-3%',
        fontWeight: 400,
        ...supportVariableFont('regular', { fontVariationSettings: "'wdth' 400" }),
      },
      subtitle1: {
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '-4%',
        fontWeight: 700,
        ...supportVariableFont('bold', { fontVariationSettings: "'wdth' 700" }),
      },
      subtitle2: {
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '-4%',
        fontWeight: 350,
        ...supportVariableFont('regular', {
          fontVariationSettings: "'wdth' 350",
        }),
      },
      body1: {
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: '-2%',
        fontWeight: 700,
        ...supportVariableFont('bold', {
          fontVariationSettings: "'wdth' 700",
        }),
      },
      body2: {
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: '-2%',
        fontWeight: 350,
        ...supportVariableFont('regular', {
          fontVariationSettings: "'wdth' 350",
        }),
      },
      caption: {
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '-2%',
        fontWeight: 350,
        ...supportVariableFont('regular', {
          fontVariationSettings: "'wdth' 350",
        }),
      },
      overline: {
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '-2%',
        fontWeight: 350,
        ...supportVariableFont('regular', {
          fontVariationSettings: "'wdth' 350",
        }),
      },
      button: {
        fontWeight: 400,
        textTransform: 'none',
        ...supportVariableFont('regular', {
          fontVariationSettings: "'wdth' 400",
        }),
      },
    },
  };
  return createTheme(themeOptions);
}
